export interface IUseMigrationProps {
  setInitialized: () => void;
}

export enum DeliveryGroup {
  HAMBURG = "hamburg",
  COLOGNE = "cologne",
  DUSSELDORF = "dusseldorf",
  BERLIN = "berlin",
  FRANKFURT = "frankfurt",
}
