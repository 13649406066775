import { merge } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { NewAddedProductSequenceIDsState } from "src/state/Menu/Menu.state";
import {
  areAddonsEqual,
  isRemovedIngredientsEqual,
} from "src/views/MenuViewPage/hooks/useConfigurator/helper";

import { APIResponse } from "../../api/fetcher";
import { verifyOrder } from "../../api/Order/order.api";
import {
  CartState,
  OrderState,
  PersistedB2BCartState,
  PersistedCartState,
  PreOrderIdState,
  TotalProductPriceState,
} from "../../state/Order/Order.state";
import { StoreState } from "../../state/Store/Store.state";
import {
  InternalCartItem,
  mapInternalCartItemToRequestCartItem,
  mapOrderResponseCartItemToStoredCartItem,
  mapStoredCartItemToInternalCartItem,
  PersistedCartItem,
} from "../../util/order";
import {
  trackAddToCartEvent,
  trackRemoveFromCartEvent,
} from "../../util/tracking.util";
import {
  CartActiveState,
  MenuBackgroundBlurActiveState,
} from "../../views/MenuViewPage/state/Menu";
import useAccount from "../useAccount";
import useAddress from "../useAddress";
import useErrorHandler from "../useErrorHandler";
import { ProductError } from "../useErrorHandler/useErrorHandler.interfaces";
import useProducts from "../useProducts/useProducts";
import useUserInfos from "../useUserInfos";
import {
  AddToCartMethod,
  IUseCart,
  RemoveFromCartMethod,
} from "./useCart.interfaces";
import OrderResponse = Definitions.OrderResponse;

const useCart = (): IUseCart => {
  const [order, setOrder] = useRecoilState(OrderState);
  const setTotalProductPriceWithVat = useSetRecoilState(TotalProductPriceState);
  const [cartActive, setCartActive] = useRecoilState(CartActiveState);
  const setBackgroundBlurActive = useSetRecoilState(
    MenuBackgroundBlurActiveState
  );
  const { addErrors, productErrors } = useErrorHandler();
  const { getProductById, products } = useProducts();
  const { isB2B } = useAccount();
  const [internalCart, setInternalCart] = useRecoilState(CartState);
  const [persistedCart, setPersistedCart] = useRecoilState(
    isB2B ? PersistedB2BCartState : PersistedCartState
  );
  const activeStore = useRecoilValue(StoreState);
  const [cartIsValid, setCartIsValid] = useState(
    productErrors.length === 0 && internalCart.length > 0
  );
  const { phoneNumber, email } = useUserInfos();
  const { selectedAddress } = useAddress();
  const [preOrderId, setPreOrderId] = useRecoilState(PreOrderIdState);
  const setNewAddedProductSequenceIDs = useSetRecoilState(
    NewAddedProductSequenceIDsState
  );

  const _verifyOrder = useCallback(
    async (props?: {
      cart?: InternalCartItem[];
    }): Promise<APIResponse<OrderResponse> | null> => {
      try {
        const cart = !!props?.cart
          ? props.cart.map(mapInternalCartItemToRequestCartItem)
          : persistedCart;
        const response = await verifyOrder(activeStore.id, {
          ...order,
          preOrderId,
          cartSorted: undefined,
          deliveryTime: "now",
          deliveryInfo: {
            phoneNumber,
            emailAddress: email,
            address: {
              bellName: selectedAddress?.bellName,
              deliveryComment: selectedAddress?.deliveryComment,
              street: selectedAddress
                ? `${
                    selectedAddress?.street && selectedAddress?.houseNo
                      ? `${selectedAddress?.street} ${selectedAddress?.houseNo}`
                      : selectedAddress?.street
                  }`
                : "",
              city: selectedAddress ? selectedAddress?.city : "",
              zipCode: selectedAddress ? selectedAddress?.zipCode : "",
            },
          },
          paymentInfo: {
            method: "paypal",
            useCheckout: true,
          },
          cart,
        });
        if (response?.payload?.preOrderId) {
          setPreOrderId(response.payload?.preOrderId);
        }
        return response;
      } catch (err) {
        console.error("Verify Order Failed", err);
        return null;
      }
    },
    [
      isB2B,
      internalCart,
      activeStore,
      order,
      email,
      phoneNumber,
      selectedAddress,
      persistedCart,
      preOrderId,
      setPreOrderId,
    ]
  );
  const setCart = useCallback(
    (cart: PersistedCartItem[]) => {
      const mappedCart = cart.map(
        mapStoredCartItemToInternalCartItem(getProductById)
      );
      setInternalCart(mappedCart);
      setPersistedCart(cart);
    },
    [setInternalCart, setPersistedCart, getProductById]
  );
  useEffect(() => {
    setCartIsValid(productErrors?.length === 0 && internalCart.length > 0);
  }, [internalCart, productErrors]);

  const getItemCountBySequenceId = useCallback(
    (sequenceId: number): number => {
      const cartItem = internalCart.find(
        (item) => item.sequenceId === sequenceId
      );
      return !!cartItem ? cartItem.count : 0;
    },
    [internalCart]
  );

  const initializeCart = useCallback(async () => {
    const verifyResponse = await _verifyOrder();
    if (!verifyResponse) return;
    const { payload, errors } = verifyResponse;
    if (payload) {
      const _newCart = payload.cart.map(
        mapOrderResponseCartItemToStoredCartItem
      );
      setTotalProductPriceWithVat(payload.totalProductsPriceWithVat);
      setCart(_newCart);
    }
    if (errors.length > 0) {
      const productErrors = errors.filter(({ type }) =>
        [
          ProductError.PRODUCT_UNAVAILABLE,
          ProductError.PRODUCT_NOT_FOUND,
        ].includes(type as ProductError)
      );
      if (productErrors.length > 0) {
        addErrors(productErrors);
      }
    }
  }, [
    isB2B,
    _verifyOrder,
    setOrder,
    setTotalProductPriceWithVat,
    addErrors,
    setCart,
  ]);

  const addItemsToCart = useCallback(
    async (newItems: InternalCartItem[], cartProp?: InternalCartItem[]) => {
      const _setCart = setCart;
      const _cart = !!cartProp ? cartProp : internalCart;
      const largestSequenceId = Math.max(
        ..._cart.map((item) => item.sequenceId),
        1
      );
      const _newCart = [..._cart];

      newItems.forEach((newItem: InternalCartItem, index) => {
        const existingItemIndex = _newCart.findIndex(
          (item) =>
            item.id === newItem.id &&
            areAddonsEqual(item.addons, newItem.addons) &&
            isRemovedIngredientsEqual(
              item?.deliveryDetailInfo?.customerComment || "",
              newItem?.deliveryDetailInfo?.customerComment || ""
            )
        );
        if (existingItemIndex > -1) {
          _newCart[existingItemIndex] = {
            ..._newCart[existingItemIndex],
            count: _newCart[existingItemIndex].count + newItem.count,
          };
          setNewAddedProductSequenceIDs((prev) => [
            ...prev,
            _newCart[existingItemIndex].sequenceId,
          ]);
        } else {
          _newCart.push({
            ...newItem,
            sequenceId: largestSequenceId + index + 1,
          });
          setNewAddedProductSequenceIDs((prev) => [
            ...prev,
            largestSequenceId + index + 1,
          ]);
        }
      });

      const { payload, errors } = await _verifyOrder({ cart: _newCart });
      const productErrors = errors.filter(({ type }) =>
        [
          ProductError.PRODUCT_UNAVAILABLE,
          ProductError.PRODUCT_NOT_FOUND,
        ].includes(type as ProductError)
      );
      const newItemsIds = newItems.map((item) => item.id);

      const matchingProductErrors = productErrors.filter((error) =>
        newItemsIds.includes(error.id)
      );
      if (matchingProductErrors.length > 0) {
        addErrors(productErrors);
      } else if (payload) {
        const _newCart = payload.cart.map(
          mapOrderResponseCartItemToStoredCartItem
        );
        setOrder(merge({}, order, payload));
        setTotalProductPriceWithVat(payload.totalProductsPriceWithVat);
        _setCart(_newCart);
        trackAddToCartEvent(newItems, { preOrderId }).catch((err) => {});
      }
    },
    [
      isB2B,
      internalCart,
      setCart,
      _verifyOrder,
      setOrder,
      setTotalProductPriceWithVat,
      addErrors,
      getProductById,
      preOrderId,
    ]
  );

  const addToCart: AddToCartMethod = useCallback(
    async (productId, addons = []) => {
      try {
        const _setCart = setCart;
        const _cart = internalCart;
        const newCart: InternalCartItem[] = [..._cart];
        const product = getProductById(productId);
        const largestSequenceId = Math.max(
          ...newCart.map((item) => item.sequenceId),
          1
        );
        if (product) {
          const addedCartItem: InternalCartItem = {
            id: product.id,
            productId: product.id,
            product: product.id,
            image: product.image,
            name: product.name,
            addons,
            count: 1,
            sequenceId: largestSequenceId + 1,
            tags: product.tags,
            price: product.price,
            singlePrice: product.price,
            deliveryDetailInfo: null,
            basePrice: product.price.withVat,
            available: product.available,
          };

          const existingItemIndex = newCart.findIndex(
            (item) => item.productId === addedCartItem.productId
          );
          if (
            existingItemIndex > -1 &&
            newCart[existingItemIndex].addons.length ===
              addedCartItem.addons.length &&
            newCart[existingItemIndex].addons.every((addon) =>
              addedCartItem.addons.some(
                (addedItemAddon) =>
                  addon.id === addedItemAddon.id &&
                  addon.count === addedItemAddon.count
              )
            )
          ) {
            newCart[existingItemIndex] = {
              ...newCart[existingItemIndex],
              count: newCart[existingItemIndex].count + addedCartItem.count,
            };
            setNewAddedProductSequenceIDs((prev) => [
              ...prev,
              newCart[existingItemIndex].sequenceId,
            ]);
          } else {
            newCart.push({
              ...addedCartItem,
              sequenceId: largestSequenceId + 1,
            });
            setNewAddedProductSequenceIDs((prev) => [
              ...prev,
              largestSequenceId + 1,
            ]);
          }

          const { payload } = await _verifyOrder({
            cart: newCart,
          });
          if (payload) {
            if (!cartActive) {
              setCartActive(true);
              setBackgroundBlurActive(true);
            }
            const _newCart = payload.cart.map(
              mapOrderResponseCartItemToStoredCartItem
            );
            setOrder(merge({}, order, payload));
            setTotalProductPriceWithVat(payload.totalProductsPriceWithVat);
            _setCart(_newCart);
            await trackAddToCartEvent([addedCartItem], { preOrderId });
          }
          return newCart;
        } else {
          addErrors([{ type: ProductError.PRODUCT_NOT_FOUND, id: productId }]);
        }
      } catch (err) {
        return null;
      }
    },
    [
      addErrors,
      setOrder,
      cartActive,
      setCartActive,
      setBackgroundBlurActive,
      internalCart,
      setCart,
      getProductById,
      _verifyOrder,
      products,
      preOrderId,
    ]
  );
  const removeUnavailableItemsFromCart = useCallback(async () => {
    const _setCart = setCart;
    const _cart = internalCart;
    const newCart = _cart
      .map((item) => {
        const hasError = productErrors.some((error) => error.id === item.id);

        if (hasError) {
          return null;
        }

        const filteredAddons = item.addons?.filter(
          (addon) => !productErrors.some((error) => error.id === addon.id)
        );

        return {
          ...item,
          addons: filteredAddons,
        };
      })
      .filter((item) => item !== null);
    const { payload, errors } = await _verifyOrder({ cart: newCart });
    const _newCart = payload.cart.map(mapOrderResponseCartItemToStoredCartItem);
    _setCart(_newCart);
  }, [internalCart, setCart, _verifyOrder, productErrors]);

  const removeFromCart: RemoveFromCartMethod = useCallback(
    async (sequenceId, deleteCount) => {
      const _setCart = setCart;
      const _cart = internalCart;
      const itemIndex = _cart.findIndex(
        (item) => item.sequenceId === sequenceId
      );
      const product = activeStore?.products.find(
        (product) => product.id === _cart[itemIndex].productId
      );
      const itemCount = getItemCountBySequenceId(sequenceId);
      let newCart = [..._cart];
      if (itemIndex > -1) {
        if (
          (deleteCount && newCart[itemIndex].count - deleteCount < 1) ||
          !deleteCount
        ) {
          //remove from cart
          newCart.splice(itemIndex, 1);
        } else {
          //reduce product at index by count
          newCart[itemIndex] = {
            ...newCart[itemIndex],
            count: newCart[itemIndex].count - deleteCount,
          };
        }
      }
      const { payload } = await _verifyOrder({ cart: newCart });
      if (payload) {
        const _newCart = payload.cart.map(
          mapOrderResponseCartItemToStoredCartItem
        );
        setOrder(merge({}, order, payload));
        setTotalProductPriceWithVat(payload.totalProductsPriceWithVat);
        _setCart(_newCart);
        await trackRemoveFromCartEvent(
          product,
          !!deleteCount ? deleteCount : itemCount,
          { preOrderId }
        );
      }
      return newCart;
    },
    [
      getProductById,
      _verifyOrder,
      internalCart,
      setCart,
      setOrder,
      setTotalProductPriceWithVat,
      preOrderId,
    ]
  );

  const updateCartItem = useCallback(
    async (sequenceId: number, updatedCartItem: InternalCartItem) => {
      const _setCart = setCart;
      const _cart = internalCart;
      const itemIndex = _cart.findIndex(
        (item) => item.sequenceId === sequenceId
      );

      let newCart = [..._cart];
      if (itemIndex > -1) {
        newCart[itemIndex] = updatedCartItem;
      }

      const { payload, errors: _errors } = await _verifyOrder({
        cart: newCart,
      });
      if (payload) {
        const _newCart = payload.cart.map(
          mapOrderResponseCartItemToStoredCartItem
        );
        setOrder(merge({}, order, payload));
        setTotalProductPriceWithVat(payload.totalProductsPriceWithVat);
        _setCart(_newCart);
      }
      setNewAddedProductSequenceIDs((prev) => [...prev, sequenceId]);
      return newCart;
    },
    [
      getProductById,
      _verifyOrder,
      internalCart,
      setCart,
      setOrder,
      setTotalProductPriceWithVat,
    ]
  );

  return {
    addToCart,
    addItemsToCart,
    updateCartItem,
    removeFromCart,
    removeUnavailableItemsFromCart,
    getItemCountBySequenceId,
    cart: internalCart,
    cartIsValid,
    initializeCart,
  };
};

export default useCart;
