import useSWR, { SWRConfiguration } from "swr";

import { Fetcher, FetcherFunctionResponse } from "../fetcher";
import { PublicAPIFetcher } from "../fetcher/publicAPI/publicAPIFetcher";
import {
  GenericLoginRequest,
  GenericLoginResponse,
} from "./genericAccount.interfaces";
import BasicAccountInfo = Definitions.BasicAccountInfo;

const ACCOUNT_BASE_PATH = "/account";
export const login = async (data: GenericLoginRequest) => {
  return PublicAPIFetcher<GenericLoginResponse>(`${ACCOUNT_BASE_PATH}/login`, {
    method: "POST",
    data,
  });
};

export const whoAmI = (
  _userToken: string | false,
  swrConfig?: SWRConfiguration
): FetcherFunctionResponse<BasicAccountInfo | undefined> => {
  const { error, data, mutate } = useSWR<BasicAccountInfo | undefined, string>(
    () => (_userToken ? `whoAmI_${_userToken}` : null),
    () =>
      _userToken
        ? Fetcher<BasicAccountInfo>("/account/whoami", {
            method: "GET",
            token: _userToken as string,
          })
        : null,
    swrConfig
  );
  return {
    error,
    data,
    mutate,
  };
};

export const resetPassword = async (email: string) => {
  return PublicAPIFetcher("/account/requestPasswordRecovery", {
    method: "POST",
    data: { email },
  });
};
