import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";

import {
  deleteAddress,
  getAddressBook,
  getCompany,
  saveAddress,
  setStandardDeliveryAddress,
  setStandardInvoiceAddress,
  updateAddress,
  whoAmI,
} from "../../../../api/Company/company.api";
import { GenericLoginResponse } from "../../../../api/GenericAccount/genericAccount.interfaces";
import { B2BAuthState, TokenState } from "../../../../state/Auth/Auth.state";
import { IUseAccountBase, TGenericToken } from "../../useAccount.interfaces";
import AddressBookEntryInfo = Definitions.AddressBookEntryInfo;

export const isB2BTokenValid = (token: string | null): boolean =>
  !!token && token !== "null";
const getB2BToken = (
  legacyToken: string | null,
  genericToken: TGenericToken | null
) => {
  if (!!genericToken) {
    if (genericToken.scope === "B2B") {
      return genericToken.id;
    } else {
      return null;
    }
  }
  return legacyToken;
};
const useB2B = (): IUseAccountBase => {
  const [legacyB2BToken, setLegacyB2BToken] = useRecoilState(B2BAuthState);
  const [genericToken, setGenericToken] = useRecoilState(TokenState);

  const [isLoggedIn, setIsLoggedIn] = useState(
    !!getB2BToken(legacyB2BToken, genericToken)
  );

  const _getAddressBook = async () => {
    try {
      const addressBook = await getAddressBook();
      return addressBook;
    } catch (err) {}
  };

  const _saveAddress = async (address: Omit<AddressBookEntryInfo, "id">) => {
    try {
      return await saveAddress(address);
    } catch (err) {}
  };

  const _updateAddress = async (updatedAddress: AddressBookEntryInfo) => {
    try {
      return await updateAddress(updatedAddress);
    } catch (err) {}
  };

  const _deleteAddress = async (id: string) => {
    try {
      await deleteAddress(id);
    } catch (err) {}
  };

  const _setStandardDeliveryAddress = async (id: string) => {
    try {
      return await setStandardDeliveryAddress(id);
    } catch (err) {}
  };

  const _setStandardInvoiceAddress = async (id: string) => {
    try {
      return await setStandardInvoiceAddress(id);
    } catch (err) {}
  };

  useEffect(() => {
    const token = getB2BToken(legacyB2BToken, genericToken);
    setIsLoggedIn(!!token);
  }, [genericToken, legacyB2BToken]);

  let props = {
    isLoggedIn,
    getAddressBook: _getAddressBook,
    saveAddress: _saveAddress,
    updateAddress: _updateAddress,
    deleteAddress: _deleteAddress,
    setStandardDeliveryAddress: _setStandardDeliveryAddress,
    setStandardInvoiceAddress: _setStandardInvoiceAddress,
    setToken: (loginResponse?: GenericLoginResponse) => {
      if (loginResponse) {
        const { id, scope, userId, ttl, timeout } = loginResponse;
        setGenericToken({ id, scope, userId, ttl, timeout });
        setLegacyB2BToken(id);
      } else {
        setGenericToken(null);
        setLegacyB2BToken(null);
      }
    },
  };

  const userAccount = whoAmI(getB2BToken(legacyB2BToken, genericToken));
  const companyDetails = getCompany(getB2BToken(legacyB2BToken, genericToken));

  return {
    ...props,
    userAccount,
    companyDetails,
  };
};

export default useB2B;
