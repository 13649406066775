import { atom, selector } from "recoil";

import { isB2BTokenValid } from "../../hooks/useAccount/hooks/useB2B/useB2B";
import { isTokenValid } from "../../hooks/useAccount/hooks/useB2C/useB2C";
import { TGenericToken } from "../../hooks/useAccount/useAccount.interfaces";
import {
  B2BAuthTokenStorageAdapter,
  GenericAuthTokenStorageAdapter,
  UserAuthTokenStorageAdapter,
} from "../../util/storage/auth.storage";
import { localStorageEffect } from "../effect";

export const TokenState = atom<TGenericToken | null>({
  default: null,
  key: "_TokenState",
  effects: [
    localStorageEffect({
      get: GenericAuthTokenStorageAdapter.get,
      set: GenericAuthTokenStorageAdapter.set,
      delete: GenericAuthTokenStorageAdapter.delete,
    }),
  ],
});

export const UserAuthState = atom<string | null>({
  key: "_UserAuthState",
  default: null,
  effects: [
    localStorageEffect({
      get: UserAuthTokenStorageAdapter.get,
      set: UserAuthTokenStorageAdapter.set,
      delete: UserAuthTokenStorageAdapter.delete,
    }),
  ],
});

export const UserIsLoggedInState = selector<boolean>({
  key: "_USerIsLoggedInState",
  get: ({ get }) => isTokenValid(get(UserAuthState)),
});

export const B2BAuthState = atom<string | null>({
  key: "_B2BAuthState",
  default: null,
  effects: [
    localStorageEffect({
      get: B2BAuthTokenStorageAdapter.get,
      set: B2BAuthTokenStorageAdapter.set,
      delete: B2BAuthTokenStorageAdapter.delete,
    }),
  ],
});

export const B2BIsLoggedInState = selector<boolean>({
  key: "_B2BIsLoggedInState",
  get: ({ get }) => isB2BTokenValid(get(B2BAuthState)),
});
export const LoginDialogOpenState = atom<boolean>({
  key: "_LoginDialogOpenState",
  default: false,
});

export const AccountMenuOpenState = atom<boolean>({
  key: "_AccountMenuOpenState",
  default: false,
});
