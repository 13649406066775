import {
  ErrorType,
  ProductError,
} from "src/hooks/useErrorHandler/useErrorHandler.interfaces";
import {
  InternalCartItem,
  InternalCartItemAddon,
  mapInternalCartItemToRequestCartItem,
} from "src/util/order";

import { IngredientType } from "../../components/Configurator/components/InfoView/components/Ingredients/Ingredients.interfaces";
import {
  ProductCategories,
  SelectedProductsByCategory,
} from "./useConfigurator.interfaces";
import CartItem = Definitions.CartItem;
import HydratedProduct = Definitions.HydratedProduct;
import DeliveryInfo = Definitions.DeliveryInfo;
import NewOrderRequest = Definitions.NewOrderRequest;
import CartItemResponse = Definitions.CartItemResponse;

export const getProductCategoryByProduct = (product: HydratedProduct) => {
  if (product.tags.includes("product.bowl")) return ProductCategories.BOWLS;
  if (product.tags.includes("ingredient.base")) return ProductCategories.BASES;
  if (product.tags.includes("ingredient.standard"))
    return ProductCategories.STANDARDS;
  if (product.tags.includes("ingredient.premium"))
    return ProductCategories.PREMIUMS;
  if (product.tags.includes("ingredient.topping"))
    return ProductCategories.TOPPINGS;
  if (product.tags.includes("ingredient.dressing"))
    return ProductCategories.DRESSINGS;
  if (product.tags.includes("product.side")) return ProductCategories.SIDES;
  if (product.tags.includes("addon.bread")) return ProductCategories.EXTRAS;
  if (product.tags.includes("product.dessert"))
    return ProductCategories.DESSERTS;
  if (product.tags.includes("product.drink")) return ProductCategories.DRINKS;
};

export const updateURL = (addons: CartItem[], _configuratorId: string) => {
  const baseUrl = window.location.origin + window.location.pathname;
  const queryString = addons
    .map(
      (addon) =>
        `${encodeURIComponent(addon.id)}:${encodeURIComponent(addon.count)}`
    )
    .join(";");
  const newUrl = `${baseUrl}?bowl=${_configuratorId}&addons=${queryString}`;
  return newUrl;
};

export const generateMapSelectedProductToShadowOrderBase =
  (getProductById: (productId: string) => HydratedProduct) =>
  (selectedProduct: CartItem) => {
    if (!selectedProduct.addons) return {} as SelectedProductsByCategory;
    const shadowOrder: SelectedProductsByCategory = {
      product: { [selectedProduct.productId]: selectedProduct.count },
      [ProductCategories.ADDONS]: {},
      [ProductCategories.DRINKS]: {},
      [ProductCategories.DESSERTS]: {},
      [ProductCategories.SIDES]: {},
      [ProductCategories.DRESSINGS]: {},
      [ProductCategories.BASES]: {},
      [ProductCategories.STANDARDS]: {},
      [ProductCategories.PREMIUMS]: {},
      [ProductCategories.TOPPINGS]: {},
    };
    selectedProduct.addons.forEach((incl) => {
      const product = getProductById(incl.productId);
      if (product) {
        const productCount = incl.count;
        const productCategory = getProductCategoryByProduct(product);
        shadowOrder[productCategory][incl.productId] = productCount || 1;
      }
    });
    return shadowOrder;
  };

export const mapCheckoutProductToCartItem = (
  product: HydratedProduct,
  count: number = 1,
  sequenceId: number = 1,
  addons?: InternalCartItemAddon[]
): InternalCartItem => {
  return {
    id: product?.id || "",
    product: product?.id || "",
    productId: product?.id || "",
    productName: product?.name || "",
    count: count || 1,
    price: product?.price || 0,
    singlePrice: product?.price || 0,
    basePrice: product?.price?.withVat || 0,
    sequenceId: sequenceId || 1,
    addons: !!addons
      ? addons
      : product?.includes.map((p) => mapCheckoutProductToCartItem(p)) || [],
    tags: product?.tags || [],
  } as InternalCartItem;
};

type ExtendedCartItem = CartItemResponse & {
  freeItemCount: number;
  includedAddon: boolean;
};

export const mapCartItemsToIngredientList = (
  cart: CartItemResponse[],
  configuratorId: string,
  getProductById: (productId: string) => HydratedProduct
): IngredientType[] => {
  if (!cart) return [];

  const configuratorAddons = cart
    .find((p) => p.id === configuratorId)
    ?.addons.map((p: ExtendedCartItem) => {
      return {
        ...getProductById(p.id),
        count: p.count,
        freeItemCount: p.includedAddon ? 1 : 0,
      };
    })
    .reduce((prev, cur) => {
      const prevIndex = prev.findIndex((p) => p.id === cur.id);
      if (prevIndex === -1) {
        return [...prev, cur];
      }
      return prev.map((addon, index) => {
        if (index === prevIndex) {
          return {
            ...addon,
            count: addon.count + cur.count,
            freeItemCount: addon.freeItemCount + cur.freeItemCount,
          };
        }
        return addon;
      });
    }, []);

  return [
    {
      name: "Base",
      combined: true,
      products:
        configuratorAddons
          ?.filter((p) => p.productTags?.includes("ingredient.base"))
          .map((p) => {
            return {
              name: p.name,
              price: p.price.withVat,
              count: p.count,
              freeItemCount: p.freeItemCount,
            };
          }) || [],
    },
    {
      name: "Standards",
      combined: true,
      products:
        configuratorAddons
          ?.filter((p) => p.productTags?.includes("ingredient.standard"))
          .map((p) => {
            return {
              name: p.name,
              price: p.price.withVat,
              count: p.count,
              freeItemCount: p.freeItemCount,
            };
          }) || [],
    },
    {
      name: "Premiums",
      combined: true,
      products:
        configuratorAddons
          ?.filter((p) => p.productTags?.includes("ingredient.premium"))
          .map((p) => {
            return {
              name: p.name,
              price: p.price.withVat,
              count: p.count,
              freeItemCount: p.freeItemCount,
            };
          }) || [],
    },
    {
      name: "Toppings",
      combined: true,
      products:
        configuratorAddons
          ?.filter((p) => p.productTags?.includes("ingredient.topping"))
          .map((p) => {
            return {
              name: p.name,
              price: p.price.withVat,
              count: p.count,
              freeItemCount: p.freeItemCount,
            };
          }) || [],
    },
    {
      name: "Dressings",
      combined: true,
      products:
        configuratorAddons
          ?.filter((p) => p.productTags?.includes("ingredient.dressing"))
          .map((p) => {
            return {
              name: p.name,
              price: p.price.withVat,
              count: p.count,
              freeItemCount: p.freeItemCount,
            };
          }) || [],
    },
    {
      name: "Extras",
      combined: false,
      products: cart
        .filter((p) => p.id !== configuratorId)
        .map((p) => {
          return { ...getProductById(p.id), count: p.count, price: p.price };
        })
        .filter((p) => {
          return (
            p.productTags?.includes("product.dessert") ||
            p.productTags?.includes("product.drink") ||
            p.productTags?.includes("product.side") ||
            p.productTags?.includes("addon.bread")
          );
        })
        .map((p) => {
          return {
            name: p.name,
            price: p.price.withVat,
            count: p.count,
            freeItemCount: 0,
          };
        }),
    },
  ];
};

export const getProductErrors = (errors: ErrorType[]) => {
  if (
    errors.some((err) =>
      Object.values(ProductError).includes(err.type as ProductError)
    )
  ) {
    return errors.filter((err) =>
      Object.values(ProductError).includes(err.type as ProductError)
    );
  } else {
    return [];
  }
};

export const getNewOrderRequestObject = (
  preOrderId: string,
  props?: { cart: InternalCartItem[] }
): Omit<NewOrderRequest, "validateOnly"> => {
  return {
    cart: props.cart.map(mapInternalCartItemToRequestCartItem),
    paymentInfo: {
      method: "paypal",
    },
    deliveryTime: "now",
    deliveryInfo: {} as DeliveryInfo,
    preOrderId,
  };
};

export const getIncludedAddons = (
  configuratorId: string,
  oldCart: InternalCartItem[]
) => {
  const addonCountsMap = new Map();
  const _newCart = oldCart.map((item) => {
    if (item.id === configuratorId) {
      const addons = item.addons;

      addons.forEach((addon) => {
        if (!addonCountsMap.has(addon.id)) {
          addonCountsMap.set(addon.id, {
            ...addon,
            count: 0,
            freeItemCount: 0,
          });
        }
        const addonCount = addonCountsMap.get(addon.id);
        addonCount.count += 1;
        if (addon.includedAddon) {
          addonCount.freeItemCount += 1;
        }
      });

      const reducedAddons = Array.from(addonCountsMap.values());

      return {
        ...item,
        addons: reducedAddons,
      };
    }
    return item;
  });
  return _newCart;
};

export const getAllAddons = (products: HydratedProduct[]) => {
  const _products = products.filter(
    (product) =>
      !["uber-eats-delivery-price", "besteck", "custom-bowl"].includes(
        product.id
      )
  );
  const productMap = _products.reduce((acc, product) => {
    product.productTags.forEach((tag) => {
      if (
        tag === "category.top-seller" ||
        tag === "product.bowl" ||
        tag === "product.side" ||
        tag === "ingredient.premium" ||
        tag === "ingredient.dressing" ||
        tag === "ingredient.topping" ||
        tag === "product.dessert" ||
        tag === "product.drink" ||
        tag === "ingredient.base" ||
        tag === "ingredient.standard" ||
        tag === "addon.bread"
      ) {
        if (!acc[tag]) {
          acc[tag] = [];
        }
        acc[tag].push(product);
      }
    });
    return acc;
  }, {});
  return productMap;
};

export const isRemovedIngredientsEqual = (
  customerComment1: string,
  customerComment2: string
): boolean => {
  if (customerComment1 === customerComment2) return true;
  if (!customerComment1 || !customerComment2) return false;
  const removedIngredients1 = customerComment1
    .split(", ")
    .map((ingredient) => ingredient.replace("ohne ", ""));
  const removedIngredients2 = customerComment2
    .split(", ")
    .map((ingredient) => ingredient.replace("ohne ", ""));
  return (
    removedIngredients1.length === removedIngredients2.length &&
    removedIngredients1.every((ingredient) =>
      removedIngredients2.includes(ingredient)
    )
  );
};

export const areAddonsEqual = (
  addons1: InternalCartItemAddon[],
  addons2: InternalCartItemAddon[]
): boolean => {
  if (addons1.length !== addons2.length) return false;
  if (addons1.length === 0 && addons2.length === 0) return true;
  if (addons1.length === 0 || addons2.length === 0) return false;
  const sortedAddons1 = addons1
    .slice()
    .sort((a, b) => a.id.localeCompare(b.id));
  const sortedAddons2 = addons2
    .slice()
    .sort((a, b) => a.id.localeCompare(b.id));
  return sortedAddons1.every(
    (addon, index) =>
      addon.id === sortedAddons2[index].id &&
      addon.count === sortedAddons2[index].count
  );
};
