import { useCallback } from "react";
import { useRecoilValue } from "recoil";

import { CMSState } from "../../state/CMS/CMS.state";
import { IUseCMS, TGetCMSValueByKeyGenerator } from "./useCMS.interfaces";

const useCMS = (): IUseCMS => {
  const cmsData = useRecoilValue(CMSState);
  const getCMSValueByKeyGenerator: TGetCMSValueByKeyGenerator = useCallback(
    (sectionKey) => (valueKey) => {
      if (cmsData) {
        const cmsSection = cmsData[sectionKey as string];
        if (cmsSection) {
          const value = cmsSection[valueKey as string];
          if (value) return value;
        }
      }
      return null;
    },
    [cmsData]
  );
  return {
    getCMSValueByKeyGenerator,
    cmsData,
  };
};

export default useCMS;
