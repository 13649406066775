import { atom, selector } from "recoil";

import { ProductFilter } from "../../hooks/useFilter/useFilter.interfaces";

export const CartActiveState = atom<boolean>({
  key: "_CartActiveState",
  default: false,
});

export const MenuDialogOpenState = atom<boolean>({
  key: "_MenuDialogOpenState",
  default: false,
});

export const AvailableFilterState = atom<ProductFilter[]>({
  key: "_AvailableFilterState",
  default: [],
});

export const ActiveFilterState = atom<string[]>({
  key: "_ActiveFilterState",
  default: [],
});

export const MenuScrollActiveCategory = atom<string | null>({
  key: "_MenuScrollActiveCategory",
  default: null,
});

export const MenuDeliveryAreaModalOpenState = atom<boolean>({
  key: "_MenuDeliveryAreaModalOpenState",
  default: false,
});

export const MenuFilterOpenState = atom<boolean>({
  key: "_MenuFilterOpenState",
  default: false,
});

export const StoreAlertBoxHeightState = atom<number>({
  key: "_StoreAlertBoxHeightState",
  default: 0,
});

export const MenuBackgroundBlurActiveState = atom<boolean>({
  key: "_MenuBackgroundBlurActiveState",
  default: false,
});

export const UpsellModalActiveState = atom<boolean>({
  key: "_UpsellModalActiveState",
  default: false,
});

export const StoreVoucherBoxHeightState = atom<number>({
  key: "_StoreVoucherBoxHeightState",
  default: 0,
});

export const StoreAlertAndVoucherBoxHeightSelector = selector<number>({
  key: "_StoreAlertAndVoucherBoxHeightSelector",
  get: ({ get }) => {
    const alertBoxHeight = get(StoreAlertBoxHeightState);
    const voucherBoxHeight = get(StoreVoucherBoxHeightState);

    return alertBoxHeight + voucherBoxHeight;
  },
  cachePolicy_UNSTABLE: {
    eviction: "most-recent",
  },
});
