import { useCallback, useEffect } from "react";
import { useRecoilState } from "recoil";

import {
  ErrorCauseMapState,
  ErrorState,
  GenericErrorState,
  InputErrorState,
  OrderErrorState,
  PaymentErrorState,
  PlaceOrderErrorState,
  ProductErrorState,
  UserErrorState,
  VoucherErrorState,
  VytalErrorState,
} from "../../state/Error/Error.state";
import { CheckoutError } from "../../views/CheckoutPageView/CheckoutPage/CheckoutPage.interfaces";
import { ErrorTypeMap } from "./errorTypeMap";
import {
  ErrorType,
  GenericError,
  InputError,
  IUseErrorHandler,
  OrderError,
  PaymentError,
  PlaceOrderError,
  ProductError,
  UseErrorHandlerProps,
  UserError,
  VoucherError,
  VytalError,
} from "./useErrorHandler.interfaces";

const useErrorHandler = (
  props: UseErrorHandlerProps = {}
): IUseErrorHandler => {
  const { cmsErrorMap } = props;

  const [errorCauseMap, setErrorCauseMap] = useRecoilState(ErrorCauseMapState);
  const [errors, setErrors] = useRecoilState(ErrorState);
  const [inputError, setInputError] = useRecoilState(InputErrorState);
  const [productErrors, setProductErrors] = useRecoilState(ProductErrorState);
  const [orderError, setOrderError] = useRecoilState(OrderErrorState);
  const [voucherError, setVoucherError] = useRecoilState(VoucherErrorState);
  const [paymentError, setPaymentError] = useRecoilState(PaymentErrorState);
  const [genericError, setGenericError] = useRecoilState(GenericErrorState);
  const [userError, setUserError] = useRecoilState(UserErrorState);
  const [vytalError, setVytalError] = useRecoilState(VytalErrorState);
  const [placeOrderError, setPlaceOrderError] =
    useRecoilState(PlaceOrderErrorState);

  const getErrorMessageByType = useCallback(
    (type: ErrorType["type"] | CheckoutError, cause?: string) => {
      return errorCauseMap
        ? ErrorTypeMap[type](errorCauseMap[type], cause)
          ? ErrorTypeMap[type](errorCauseMap[type], cause)
          : ErrorTypeMap[GenericError.GENERIC_ERROR](
              errorCauseMap[GenericError.GENERIC_ERROR]
            )
        : "Es ist etwas schief gelaufen.";
    },
    [cmsErrorMap, errorCauseMap]
  );

  useEffect(() => {
    if (!!cmsErrorMap) {
      setErrorCauseMap(cmsErrorMap);
    }
  }, [cmsErrorMap]);

  useEffect(() => {
    //Input Errors
    if (
      errors.some((err) =>
        Object.values(InputError).includes(err.type as InputError)
      )
    ) {
      setInputError(
        errors.find((err) =>
          Object.values(InputError).includes(err.type as InputError)
        )
      );
    } else {
      setInputError(null);
    }
    //Product Error
    if (
      errors.some((err) =>
        Object.values(ProductError).includes(err.type as ProductError)
      )
    ) {
      setProductErrors(
        errors.filter((err) =>
          Object.values(ProductError).includes(err.type as ProductError)
        )
      );
    } else {
      setProductErrors([]);
    }
    //Order Errors
    if (
      errors.some((err) =>
        Object.values(OrderError).includes(err.type as OrderError)
      )
    ) {
      setOrderError(
        errors.find((err) =>
          Object.values(OrderError).includes(err.type as OrderError)
        )
      );
    } else {
      setOrderError(null);
    }
    //Voucher Error
    if (
      errors.some((err) =>
        Object.values(VoucherError).includes(err.type as VoucherError)
      )
    ) {
      setVoucherError(
        errors.find((err) =>
          Object.values(VoucherError).includes(err.type as VoucherError)
        )
      );
    } else {
      setVoucherError(null);
    }
    //Payment Error
    if (
      errors.some((err) =>
        Object.values(PaymentError).includes(err.type as PaymentError)
      )
    ) {
      setPaymentError(
        errors.find((err) =>
          Object.values(PaymentError).includes(err.type as PaymentError)
        )
      );
    } else {
      setPaymentError(null);
    }
    //Generic Error
    if (
      errors.some((err) =>
        Object.values(GenericError).includes(err.type as GenericError)
      )
    ) {
      setGenericError(
        errors.find((err) =>
          Object.values(GenericError).includes(err.type as GenericError)
        )
      );
    } else {
      setGenericError(null);
    }
    //User Error
    if (
      errors.some((err) =>
        Object.values(UserError).includes(err.type as UserError)
      )
    ) {
      setUserError(
        errors.find((err) =>
          Object.values(UserError).includes(err.type as UserError)
        )
      );
    } else {
      setUserError(null);
    }
    //Vytal Error
    if (
      errors.some((err) =>
        Object.values(VytalError).includes(err.type as VytalError)
      )
    ) {
      setVytalError(
        errors.find((err) =>
          Object.values(VytalError).includes(err.type as VytalError)
        )
      );
    } else {
      setVytalError(null);
    }
    if (
      errors.some((err) =>
        Object.values(PlaceOrderError).includes(err.type as PlaceOrderError)
      )
    ) {
      setPlaceOrderError(
        errors.find((err) =>
          Object.values(PlaceOrderError).includes(err.type as PlaceOrderError)
        )
      );
    }
  }, [errors]);

  const addErrorsProxy = useCallback(
    (newErrors: ErrorType[]) => {
      setErrors(newErrors);
    },
    [setErrors]
  );

  const resetErrors = useCallback(
    (errorType?: ErrorType["type"] | ErrorType["type"][]) => {
      let errorTypes = [errorType].flat(1);
      const filteredErrors = !!errorType
        ? errors.filter((error) => !errorTypes.includes(error.type))
        : [];
      setErrors(filteredErrors);
    },
    [setErrors, errors]
  );
  return {
    errors,
    setErrors,
    addErrors: addErrorsProxy,
    resetErrors,
    inputError,
    productErrors,
    orderError,
    voucherError,
    paymentError,
    genericError,
    userError,
    vytalError,
    placeOrderError,
    getErrorMessageByType,
  };
};

export default useErrorHandler;

export const filterUnavailableProductIds = (errors: ErrorType[]) => {
  return errors
    .filter((error) => error.type === ProductError.PRODUCT_UNAVAILABLE)
    .map((error) => error.id)
    .filter((id) => !!id);
};
