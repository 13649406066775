import { isEqual, merge } from "lodash";
import { useRouter } from "next/router";
import { useCallback, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import {
  EditingAddressState,
  FilteredAddressState,
  IsAddressInDeliveryAreaState,
  NewAddressInvalidState,
  NewAddressState,
  NewAddressValidatedState,
  StoreAddressState,
} from "../../state/Address/Address.state";
import { ActiveDeliveryGroupState } from "../../state/Store/Store.state";
import {
  AddressesState,
  BellNameState,
  SelectedAddressIdState,
  SelectedAddressState,
  UsedAddressState,
  UserAddressBookState,
  UserFirstNameState,
  UserLastNameState,
} from "../../state/User/User.state";
import { hashAddress, isPointInsideDeliveryArea } from "../../util/addressBook";
import useAccount from "../useAccount";
import useDeepCompareEffect from "../useDeepCompareEffect";
import { Address, IUseAddress, NewAddress } from "./useAddress.interfaces";

const emptyAddress: Address = {
  id: "",
  company: "",
  bellName: "",
  deliveryComment: "",
  street: "",
  houseNo: "",
  zipCode: "",
  city: "",
  deliveryGroupId: "",
  apartment: "",
  position: { latitude: 0, longitude: 0 },
  noStorage: false,
};

const filterAddressesByDeliveryGroup = (
  addresses: Address[],
  deliveryGroupId: string
) => {
  const filteredAddresses = addresses.filter(
    (a) => a.deliveryGroupId === deliveryGroupId
  );
  return filteredAddresses;
};

const useAddress = (): IUseAddress => {
  const account = useAccount();
  const firstName = useRecoilValue(UserFirstNameState);
  const lastName = useRecoilValue(UserLastNameState);
  const [bellName, setBellName] = useRecoilState(BellNameState);
  const { isReady } = useRouter();
  const activeDeliveryGroup = useRecoilValue(ActiveDeliveryGroupState);
  const [addresses, setAddresses] = useRecoilState(AddressesState);
  const [storedAddresses, setStoredAddresses] =
    useRecoilState(UsedAddressState);

  const [userAddressBook, setUserAddressBook] =
    useRecoilState(UserAddressBookState);

  const [filteredAddresses, setFilteredAddresses] =
    useRecoilState(FilteredAddressState);
  const [selectedAddressId, setSelectedAddressById] = useRecoilState(
    SelectedAddressIdState
  );
  const [editingAddress, setEditingAddress] =
    useRecoilState(EditingAddressState);
  const [selectedAddress, setSelectedAddress] =
    useRecoilState(SelectedAddressState);
  const [newAddress, setNewAddress] = useRecoilState(NewAddressState);
  const [newAddressValidated, setNewAddressValidated] = useRecoilState(
    NewAddressValidatedState
  );
  const [newAddressInvalid, setNewAddressInvalid] = useRecoilState(
    NewAddressInvalidState
  );

  const [storeAddress, setStoreAddress] = useRecoilState(StoreAddressState);
  const [isAddressInDeliveryArea, setIsAddressInDeliveryArea] = useRecoilState(
    IsAddressInDeliveryAreaState
  );
  const getDefaultAddress = useCallback(() => {
    let defaultAddress = emptyAddress;
    if (!!account?.companyDetails?.data?.invoiceAddress) {
      defaultAddress = merge({}, defaultAddress, {
        company: account?.companyDetails?.data?.invoiceAddress?.company || "",
      });
    }
    return defaultAddress;
  }, [account?.companyDetails?.data]);
  const updateSelectedAddress = useCallback(
    async (partialAddress: Partial<Address>) => {
      try {
        let updatedAddressWithoutId = merge(
          {},
          selectedAddress,
          partialAddress
        );
        delete updatedAddressWithoutId.id;
        const id = hashAddress(updatedAddressWithoutId);
        const _selectedAddress = merge({}, selectedAddress, {
          ...partialAddress,
          id,
        });
        if (account.isLoggedIn) {
          await account.setStandardDeliveryAddress(_selectedAddress.id);
        } else {
          const filteredAddresses = addresses
            .filter((a) => !!a && !a.noStorage)
            .map((address) =>
              address.id !== selectedAddress.id ? address : _selectedAddress
            );
          setSelectedAddress(_selectedAddress);
          setStoredAddresses(filteredAddresses);
        }
      } catch (err) {}
    },
    [selectedAddress, addresses, setSelectedAddress, setStoredAddresses]
  );
  const updateAddress = useCallback(
    async (address: Address) => {
      try {
        let updatedAddressWithoutId = { ...address };
        delete updatedAddressWithoutId.id;
        const id = hashAddress(updatedAddressWithoutId);
        const updatedAddress = merge({}, updatedAddressWithoutId, { id });
        if (!!account.isLoggedIn) {
          await account.updateAddress(address);
          const addressBook = await account.getAddressBook();
          setUserAddressBook(addressBook);
        } else {
          const filteredAddresses = storedAddresses.map((a) =>
            a.id !== address.id ? a : updatedAddress
          );
          setStoredAddresses(
            filteredAddresses.filter(
              (a) => !!a && !!Object.keys(a) && !a.noStorage
            )
          );
        }
        if (address.id === selectedAddress.id) {
          setSelectedAddress(updatedAddress);
        }
      } catch (err) {}
    },
    [storedAddresses, setStoredAddresses, selectedAddress]
  );
  const saveNewAddress = useCallback(
    async (partialAddress: Partial<Address>) => {
      try {
        if (storeAddress) {
          const _newAddress = merge(
            {},
            newAddress,
            {
              bellName: bellName !== "" ? bellName : newAddress.bellName,
              firstName,
              lastName,
            },
            partialAddress
          );
          if (!!account.isLoggedIn) {
            const savedAddress = await account.saveAddress(_newAddress);
            if (savedAddress && !!savedAddress) {
              await account.setStandardDeliveryAddress(savedAddress.id);
              const addressBook = await account.getAddressBook();
              setUserAddressBook(addressBook);
              setSelectedAddressById(savedAddress.id);
            }
          } else {
            const id = hashAddress(_newAddress);
            let _address = merge({}, _newAddress, { id });
            const alreadyStored =
              storedAddresses.findIndex(
                (storedAddress) => storedAddress.id === id
              ) > -1;
            if (!alreadyStored) {
              setStoredAddresses(
                [...storedAddresses, _address].filter(
                  (a) => !!a && !a.noStorage
                )
              );
            }
            setSelectedAddressById(id);
            setSelectedAddress(_address);
            setNewAddress(null);
          }
        } else {
          setSelectedAddress(
            merge({}, newAddress, {
              id: hashAddress(newAddress),
              bellName,
              firstName,
              lastName,
              noStorage: true,
            })
          );
        }
      } catch (err) {}
    },
    [
      storedAddresses,
      setStoredAddresses,
      storeAddress,
      newAddress,
      setNewAddress,
      setSelectedAddress,
      setSelectedAddressById,
      account?.isLoggedIn,
      bellName,
      firstName,
      lastName,
      setUserAddressBook,
    ]
  );

  const deleteAddress = useCallback(
    async (addressId: string) => {
      try {
        setNewAddress(null);
        if (!!account.isLoggedIn) {
          await account.deleteAddress(addressId);
          const addressBook = await account.getAddressBook();
          setUserAddressBook(addressBook);
        } else {
          const filteredAddresses = storedAddresses.filter(
            (a) => a.id !== addressId
          );
          setStoredAddresses(filteredAddresses);
          if (selectedAddress.id === addressId) {
            setSelectedAddress(null);
            setSelectedAddressById("");
          }
        }
      } catch (err) {}
    },
    [storedAddresses, setStoredAddresses, selectedAddress]
  );

  const addNewAddress = useCallback(() => {
    let _newAddress = getDefaultAddress();
    setNewAddress(_newAddress);
    setSelectedAddress(null);
    setSelectedAddressById("");
    setNewAddressValidated(false);
    setNewAddressInvalid(false);
  }, [
    getDefaultAddress,
    setSelectedAddress,
    setSelectedAddressById,
    setNewAddressValidated,
    setNewAddressInvalid,
    setNewAddress,
    lastName,
  ]);

  useEffect(() => {
    if (
      selectedAddress &&
      "id" in selectedAddress &&
      selectedAddress.id !== ""
    ) {
      if (!!account.isLoggedIn) {
        if (selectedAddress?.id !== userAddressBook?.standardDeliveryAddress) {
          account
            .setStandardDeliveryAddress(selectedAddress.id)
            .then(() => {
              setNewAddress(null);
            })
            .catch((err) => {});
        }
      } else {
        setSelectedAddressById(selectedAddress.id);
      }
    }
  }, [selectedAddress]);

  useEffect(() => {
    if (selectedAddress && newAddress) {
      setNewAddress(null);
    }
  }, [selectedAddress]);

  useEffect(() => {
    if (activeDeliveryGroup) {
      const _filteredAddresses = filterAddressesByDeliveryGroup(
        addresses,
        activeDeliveryGroup.id
      );
      _filteredAddresses.forEach((address) => {
        if (!address.position.latitude || !address.position.longitude) {
          deleteAddress(address.id);
        }
        if (
          !!address?.position &&
          activeDeliveryGroup?.deliveryArea &&
          !isPointInsideDeliveryArea(
            address.position,
            activeDeliveryGroup.deliveryArea
          )
        ) {
          deleteAddress(address.id);
        }
      });
      if (
        !isEqual(
          _filteredAddresses
            .map((a) => a)
            .sort((a, b) => a.id.localeCompare(b.id)),
          filteredAddresses
            .map((a) => a)
            .sort((a, b) => a.id.localeCompare(b.id))
        )
      ) {
        setFilteredAddresses(_filteredAddresses);
      }
    }
  }, [activeDeliveryGroup, addresses, filteredAddresses]);

  useEffect(() => {
    if (
      selectedAddress &&
      selectedAddress?.deliveryGroupId !== activeDeliveryGroup?.id
    ) {
      setSelectedAddress(null);
    }
  }, [activeDeliveryGroup, selectedAddress]);

  useDeepCompareEffect(() => {
    if (
      account.isLoggedIn &&
      userAddressBook?.standardDeliveryAddress &&
      filteredAddresses.findIndex(
        (a) => a.id === userAddressBook.standardDeliveryAddress
      ) > -1
    ) {
      const _selectedAddress = filteredAddresses.find(
        (a) => a.id === userAddressBook.standardDeliveryAddress
      );
      if (selectedAddress?.id !== _selectedAddress?.id) {
        setSelectedAddress(_selectedAddress);
      }
    } else if (filteredAddresses.length === 1) {
      setSelectedAddress(filteredAddresses[0]);
    } else if (
      filteredAddresses.findIndex((a) => a.id === selectedAddressId) > -1
    ) {
      const selectedAddressById = filteredAddresses.find(
        (a) => a.id === selectedAddressId
      );
      setSelectedAddress(selectedAddressById);
    } else if (
      selectedAddress &&
      filteredAddresses.length > 1 &&
      filteredAddresses.findIndex((a) => a.id === selectedAddress?.id) === -1
    ) {
      setSelectedAddress(null);
    } else if (filteredAddresses.length === 0) {
      setSelectedAddress(null);
    }
  }, [filteredAddresses]);
  useDeepCompareEffect(() => {
    const _defaultAddress = getDefaultAddress();
    if (filteredAddresses.length === 0 && newAddress === null) {
      setNewAddress(_defaultAddress);
    }
  }, [filteredAddresses, newAddress, account?.companyDetails]);

  useEffect(() => {
    if (filteredAddresses.length > 0 && newAddress !== null) {
      setNewAddress(null);
    }
  }, [filteredAddresses]);

  useEffect(() => {
    const addressId = !!userAddressBook?.standardDeliveryAddress
      ? userAddressBook?.standardDeliveryAddress
      : selectedAddressId;

    if (!!addressId && !newAddress) {
      const _selectedAddress = filteredAddresses.find(
        (address) => address.id === addressId
      );
      if (_selectedAddress) {
        if (_selectedAddress.id !== selectedAddress?.id) {
          setSelectedAddress(_selectedAddress);
        }
      }
    }
  }, [
    newAddress,
    selectedAddressId,
    filteredAddresses,
    userAddressBook?.standardDeliveryAddress,
  ]);

  useEffect(() => {
    setIsAddressInDeliveryArea(
      !!selectedAddress?.position && activeDeliveryGroup?.deliveryArea
        ? isPointInsideDeliveryArea(
            selectedAddress.position,
            activeDeliveryGroup.deliveryArea
          )
        : false
    );
  }, [activeDeliveryGroup, selectedAddress]);

  useEffect(() => {
    if (isReady) {
      if (account.isLoggedIn) {
        if (userAddressBook) {
          const mappedAddresses = userAddressBook.addressBookEntries.map(
            (e) => ({
              city: e.city || "",
              deliveryGroupId: e.deliveryGroupId || "",
              bellName: e.bellName || "",
              firstName: e.firstName,
              lastName: e.lastName,
              houseNo: e.houseNo || "",
              id: e.id,
              position: {
                latitude: e.position.latitude,
                longitude: e.position.longitude,
              },
              street: e.street,
              zipCode: e.zipCode,
              company: e.company,
              apartment: e.apartment,
            })
          );
          setAddresses(mappedAddresses);
        } else {
          setAddresses([]);
        }
      } else {
        setAddresses(storedAddresses);
      }
    }
  }, [isReady, userAddressBook, storedAddresses, account.isLoggedIn]);

  const updateNewAddress = useCallback(
    (partialNewAddress: Partial<NewAddress>) => {
      setNewAddress((_newAddress) => {
        let updatedNewAddress: Partial<NewAddress> = {};
        if (!_newAddress) return;
        Object.keys(_newAddress).forEach((key) => {
          const partialAddressKeyValue = partialNewAddress[key];
          const fallbackValue =
            !!_newAddress && _newAddress !== null && !!_newAddress[key]
              ? _newAddress[key]
              : "";
          updatedNewAddress[key] =
            partialAddressKeyValue !== undefined
              ? partialAddressKeyValue
              : fallbackValue || "";
        });
        return updatedNewAddress as NewAddress;
      });
    },
    [newAddress]
  );

  return {
    addresses: filteredAddresses,
    selectedAddress,
    setSelectedAddress,
    updateAddress,
    saveNewAddress,
    deleteAddress,
    editingAddress,
    setEditingAddress,
    storeAddress,
    setStoreAddress,
    newAddress,
    updateNewAddress,
    updateSelectedAddress,
    addNewAddress,
    newAddressValidated,
    setNewAddressValidated,
    newAddressInvalid,
    setNewAddressInvalid,
    isAddressInDeliveryArea,
    setIsAddressInDeliveryArea,
  };
};

export default useAddress;
