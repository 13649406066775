import { CheckoutError } from "../../views/CheckoutPageView/CheckoutPage/CheckoutPage.interfaces";
import {
  ErrorType,
  GenericError,
  InputError,
  OrderError,
  PaymentError,
  PlaceOrderError,
  ProductError,
  UserError,
  VoucherError,
  VytalError,
} from "./useErrorHandler.interfaces";

export const ErrorTypeMap: {
  [key in ErrorType["type"] | CheckoutError]: (
    errorMessage: string,
    cause?: string
  ) => string;
} = {
  [InputError.ADDRESS_INVALID]: (errorMessage) => errorMessage,
  [InputError.EMAIL_ADDRESS_MISSING]: (errorMessage) => errorMessage,
  [InputError.FIELD_INVALID]: (errorMessage) => errorMessage,
  [InputError.NAME_MISSING]: (errorMessage) => errorMessage,
  [InputError.FIELD_MISSING]: (errorMessage) => errorMessage,
  [InputError.ADDRESS_MISSING]: (errorMessage) => errorMessage,
  [InputError.PHONE_NUMBER_MISSING]: (errorMessage) => errorMessage,
  [ProductError.PRODUCT_NOT_FOUND]: (errorMessage) => errorMessage,
  [ProductError.PRODUCT_UNAVAILABLE]: (errorMessage) => errorMessage,
  [OrderError.CHANGE_REQUEST_INVALID]: (errorMessage) => errorMessage,
  [OrderError.REQUESTED_ORDER_TIME_INVALID]: (errorMessage) => errorMessage,
  [OrderError.ORDER_IS_NULL]: (errorMessage) => errorMessage,
  [OrderError.STORE_NOT_FOUND]: (errorMessage) => errorMessage,
  [OrderError.DELIVERY_TYPE_NOT_SUPPORTED]: (errorMessage) => errorMessage,
  [VoucherError.VOUCHER_INVALID]: (errorMessage) => errorMessage,
  [VoucherError.VOUCHER_EXPIRED]: (errorMessage) => errorMessage,
  [VoucherError.VOUCHER_ALLOCATION_FAILED]: (errorMessage) => errorMessage,
  [VoucherError.VOUCHERMANAGER_COMMUNICATION_FAILED]: (errorMessage) =>
    errorMessage,
  [VoucherError.BELOW_VOUCHER_THRESHOLD]: (errorMessage, cause) =>
    `${errorMessage} ${!!cause ? `(${cause}€)` : ""}`,
  [VoucherError.VOUCHER_ONLY_FOR_NEW_CUSTOMER]: (errorMessage) => errorMessage,
  [VoucherError.VOUCHER_CREATED_FOR_STREET_MISMATCH]: (errorMessage) =>
    errorMessage,
  [VoucherError.VOUCHER_RESTRICTED_TO_DIFFERENT_DELIVERY_TIME]: (
    errorMessage,
    cause
  ) => `${errorMessage} ${!!cause ? `(${cause}Uhr)` : ""}`,
  [VoucherError.VOUCHER_RESTRICTED_TO_DIFFERENT_EMAIL_DOMAIN]: (errorMessage) =>
    errorMessage,
  [VoucherError.VOUCHER_ALREADY_USED_BY_CUSTOMER]: (errorMessage, cause) =>
    errorMessage,
  [VoucherError.VOUCHER_ALREADY_USED_BY_CUSTOMER_ON_SAME_DAY]: (errorMessage) =>
    errorMessage,
  [VoucherError.VOUCHER_RESTRICTED_TO_DIFFERENT_STORE]: (errorMessage, cause) =>
    errorMessage,
  [VoucherError.VOUCHER_RESTRICTED_TO_DIFFERENT_DELIVERY_GROUP]: (
    errorMessage,
    cause
  ) => errorMessage,
  [VoucherError.VOUCHER_REQUIRES_MORE_DATA]: (errorMessage, cause) =>
    errorMessage,
  [VoucherError.VOUCHER_NO_CREDIT]: (errorMessage, cause) => errorMessage,
  [PaymentError.PAYMENT_FAILED]: (errorMessage) => errorMessage,
  [PaymentError.UNHANDLED_PAYMENT]: (errorMessage) => errorMessage,
  [PaymentError.STORED_CREDITCARD_INVALID]: (errorMessage) => errorMessage,
  [GenericError.GENERIC_ERROR]: (errorMessage) => errorMessage,
  [UserError.PASSWORD_TOO_SHORT]: (errorMessage) => errorMessage,
  [UserError.PIN_INVALID]: (errorMessage) => errorMessage,
  [UserError.USER_EXISTS]: (errorMessage) => errorMessage,
  [UserError.USER_UNKNOWN]: (errorMessage) => errorMessage,
  [UserError.INVALID_USER_TOKEN]: (errorMessage) => errorMessage,
  [VytalError.INVALID_VYTAL_CODE]: (errorMessage) => errorMessage,
  [PlaceOrderError.FAILED]: (errorMessage) => errorMessage,
  [CheckoutError.DELIVERY_TIMESLOT_MISSING]: (errorMessage) => errorMessage,
  [CheckoutError.DELIVERY_TIMESLOT_UNAVAILABLE]: (errorMessage) => errorMessage,
  [CheckoutError.PICKUP_TIMESLOT_MISSING]: (errorMessage) => errorMessage,
  [CheckoutError.PICKUP_TIMESLOT_UNAVAILABLE]: (errorMessage) => errorMessage,
  [CheckoutError.INPUT_INVALID]: (errorMessage) => errorMessage,
  [CheckoutError.EMAIL_MISSING]: (errorMessage) => errorMessage,
  [CheckoutError.PHONE_NUMBER_MISSING]: (errorMessage) => errorMessage,
  [CheckoutError.ADDRESS_MISSING]: (errorMessage) => errorMessage,
  [CheckoutError.ADDRESS_NOT_IN_DELIVERY_AREA]: (errorMessage) => errorMessage,
  [CheckoutError.ADDRESS_INVALID]: (errorMessage) => errorMessage,
  [CheckoutError.PAYMENT_METHOD_MISSING]: (errorMessage) => errorMessage,
  [CheckoutError.VOUCHER_INVALID]: (errorMessage) => errorMessage,
  [CheckoutError.CREDIT_CARD_INVALID]: (errorMessage) => errorMessage,
  [CheckoutError.STORED_CREDIT_CARD_INVALID]: (errorMessage) => errorMessage,
  [CheckoutError.CART_INVALID]: (errorMessage) => errorMessage,
  [CheckoutError.DELIVERY_TIMESLOT_IN_PAST]: (errorMessage) => errorMessage,
  [CheckoutError.DELIVERY_TIMESLOT_NOT_WITHIN_OPENING_HOURS]: (errorMessage) =>
    errorMessage,
  [CheckoutError.PICKUP_TIMESLOT_IN_PAST]: (errorMessage) => errorMessage,
  [CheckoutError.PICKUP_TIMESLOT_NOT_WITHIN_OPENING_HOURS]: (errorMessage) =>
    errorMessage,
};
