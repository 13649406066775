import { GenericAbortSignal } from "axios";

import { APIResponse, Fetcher, PublicAPIFetcher } from "../fetcher";
import CustomerOrderInfo = Definitions.CustomerOrderInfo;
import NewOrderRequest = Definitions.NewOrderRequest;
import OrderResponse = Definitions.OrderResponse;

export const verifyOrder = async (
  storeId: string,
  newOrderRequest: Omit<NewOrderRequest, "validateOnly">,
  signal?: GenericAbortSignal
): Promise<APIResponse<OrderResponse>> => {
  const response = await Fetcher<APIResponse<OrderResponse>>(
    `/order/${storeId}/verify`,
    {
      method: "POST",
      params: {
        flagUnavailableItems: true,
      },
      data: {
        ...newOrderRequest,
      },
      signal,
      validateStatus: (number) => [200].includes(number),
    }
  );
  return response;
};

export const placeOrder = async (
  storeId: string,
  newOrderRequest: Omit<NewOrderRequest, "validateOnly">
): Promise<APIResponse<OrderResponse>> => {
  const response = await Fetcher<APIResponse<OrderResponse>>(
    `/shop/${storeId}/order`,
    {
      method: "POST",
      data: {
        ...newOrderRequest,
        validateOnly: false,
      },
      validateStatus: (number) => [200, 400].includes(number),
    }
  );
  return response;
};

export const verifyPayPalPayment = async (props: {
  merchantReference: string;
  approved: boolean;
}) => {
  const response = await PublicAPIFetcher<APIResponse<CustomerOrderInfo>>(
    "/order/verifyPayment",
    {
      method: "POST",
      data: {
        ...props,
        type: "paypal",
      },
    }
  );
  return response.payload;
};
export const verifyAdyenPayment = async (token: string) => {
  const response = await PublicAPIFetcher<APIResponse<CustomerOrderInfo>>(
    "/order/verifyPayment",
    {
      method: "POST",
      data: { token: token, type: "adyen" },
    }
  );
  return response.payload;
};
