import { isEqual } from "lodash";
import { useEffect, useRef } from "react";

type UnmountHandler = () => void;

function useDeepCompareMemoize(value) {
  const ref = useRef();
  // it can be done by using useMemo as well
  // but useRef is rather cleaner and easier

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

const useDeepCompareEffect = (
  callback: () => UnmountHandler | void,
  dependencies: any[]
) => {
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
};

export default useDeepCompareEffect;
