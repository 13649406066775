import { NewAddress } from "../hooks/useAddress";
import Position = Definitions.Position;

export const hashAddress = (address: NewAddress): string => {
  const input = JSON.stringify(address);
  let hash = 0;

  if (input.length === 0) {
    return hash.toString();
  }

  for (let i = 0; i < input.length; i++) {
    const char = input.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0; // Convert to 32-bit integer
  }

  return hash.toString();
};

export const haversineDistance = (
  pointA: Position,
  pointB: Position,
  multiplier: number = 1
): number => {
  const earthRadiusInKm = 6371;

  const lat1 = (pointA.latitude * Math.PI) / 180;
  const lon1 = (pointA.longitude * Math.PI) / 180;
  const lat2 = (pointB.latitude * Math.PI) / 180;
  const lon2 = (pointB.longitude * Math.PI) / 180;

  const dLat = lat2 - lat1;
  const dLon = lon2 - lon1;

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distanceInKm = earthRadiusInKm * c * multiplier;

  const roundedDistanceInKm = Math.ceil(distanceInKm / 0.5) * 0.5;

  return roundedDistanceInKm;
};

export const isPointInsideDeliveryArea = (
  target: Position,
  deliveryArea: Position[]
): boolean => {
  const targetLatitude = target.latitude;
  const targetLongitude = target.longitude;

  let isInside = false;
  const verticesCount = deliveryArea.length;

  for (let i = 0, j = verticesCount - 1; i < verticesCount; j = i++) {
    const vertex1 = deliveryArea[i];
    const vertex2 = deliveryArea[j];

    const vertex1Latitude = vertex1.latitude;
    const vertex1Longitude = vertex1.longitude;

    const vertex2Latitude = vertex2.latitude;
    const vertex2Longitude = vertex2.longitude;

    const intersect =
      vertex1Longitude > targetLongitude !==
        vertex2Longitude > targetLongitude &&
      targetLatitude <
        ((vertex2Latitude - vertex1Latitude) *
          (targetLongitude - vertex1Longitude)) /
          (vertex2Longitude - vertex1Longitude) +
          vertex1Latitude;

    if (intersect) {
      isInside = !isInside;
    }
  }

  return isInside;
};
