import { atom } from "recoil";

import {
  CMSErrorMap,
  ErrorType,
} from "../../hooks/useErrorHandler/useErrorHandler.interfaces";

export const ErrorState = atom<ErrorType[]>({
  key: "_ErrorState",
  default: [],
});

export const ErrorCauseMapState = atom<CMSErrorMap | null>({
  key: "_ErrorCauseMapState",
  default: null,
});

export const InputErrorState = atom<ErrorType | null>({
  key: "_InputErrorState",
  default: null,
});

export const InputErrorMessageState = atom<string>({
  key: "_InputErrorMessageState",
  default: "",
});

export const ProductErrorState = atom<ErrorType[]>({
  key: "_ProductErrorState",
  default: [],
});

export const ProductErrorMessageState = atom<string>({
  key: "_ProductErrorMessageState",
  default: "",
});

export const OrderErrorState = atom<ErrorType | null>({
  key: "_OrderErrorState",
  default: null,
});
export const OrderErrorMessageState = atom<string>({
  key: "_OrderErrorMessageState",
  default: "",
});

export const VoucherErrorState = atom<ErrorType | null>({
  key: "_VoucherErrorState",
  default: null,
});

export const VoucherErrorMessageState = atom<string>({
  key: "_VoucherErrorMessageState",
  default: "",
});

export const PaymentErrorState = atom<ErrorType | null>({
  key: "_PaymentErrorState",
  default: null,
});

export const PaymentErrorMessageState = atom<string>({
  key: "_PaymentErrorMessageState",
  default: "",
});

export const GenericErrorState = atom<ErrorType | null>({
  key: "_GenericErrorState",
  default: null,
});

export const GenericErrorMessageState = atom<string>({
  key: "_GenericErrorMessageState",
  default: "",
});

export const UserErrorState = atom<ErrorType | null>({
  key: "_UserErrorState",
  default: null,
});

export const UserErrorMessageState = atom<string>({
  key: "_UserErrorMessageState",
  default: "",
});

export const VytalErrorState = atom<ErrorType | null>({
  key: "_VytalErrorState",
  default: null,
});

export const PlaceOrderErrorState = atom<ErrorType | null>({
  key: "_PlaceOrderErrorState",
  default: null,
});

export const VytalErrorMessageState = atom<string>({
  key: "_VytalErrorMessageState",
  default: "",
});
