import { merge } from "lodash";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import {
  EmailState,
  NewsletterVetoState,
  PhoneNumberState,
  SelectedAddressIdState,
  SubscribeToNewsletterState,
  UsedAddressState,
  UserFirstNameState,
  UserLastNameState,
} from "../../../../state/User/User.state";
import { MigrationFlagState } from "../../../../state/Util/util.state";
import {
  EmailRegex,
  PhoneRegex,
} from "../../CheckoutPage/components/MyData/components/PickUpInputs";
import { DeliveryGroup, IUseMigrationProps } from "./useMigration.interfaces";

export const getDeliveryGroupIdByCity = (city: string): DeliveryGroup | "" => {
  const _city = city?.toLowerCase();
  const cityToDeliveryGroupMap: { [key: string]: DeliveryGroup } = {
    hamburg: DeliveryGroup.HAMBURG,
    cologne: DeliveryGroup.COLOGNE,
    hürth: DeliveryGroup.COLOGNE,
    dusseldorf: DeliveryGroup.DUSSELDORF,
    düsseldorf: DeliveryGroup.DUSSELDORF,
    berlin: DeliveryGroup.BERLIN,
    frankfurt: DeliveryGroup.FRANKFURT,
    "frankfurt am main": DeliveryGroup.FRANKFURT,
    "offenbach am main": DeliveryGroup.FRANKFURT,
    neuss: DeliveryGroup.DUSSELDORF,
    meerbusch: DeliveryGroup.DUSSELDORF,
  };
  return !!cityToDeliveryGroupMap[_city] ? cityToDeliveryGroupMap[_city] : "";
};

const useMigration = (props: IUseMigrationProps) => {
  const { setInitialized } = props;
  const { isReady } = useRouter();
  const [migratedToV2, setMigratedToV2] = useRecoilState(MigrationFlagState);

  const [selectedAddressId, setSelectedAddressId] = useRecoilState(
    SelectedAddressIdState
  );
  const [addresses, setAddresses] = useRecoilState(UsedAddressState);
  const [firstName, setFirstName] = useRecoilState(UserFirstNameState);
  const [lastName, setLastName] = useRecoilState(UserLastNameState);
  const [phoneNumber, setPhonenNumber] = useRecoilState(PhoneNumberState);
  const [email, setEmail] = useRecoilState(EmailState);
  const vetoNewsletter = useRecoilValue(NewsletterVetoState);
  const [_subscribeNewsletter, setSubscribeNewsletter] = useRecoilState(
    SubscribeToNewsletterState
  );

  useEffect(() => {
    if (isReady) {
      if (!migratedToV2) {
        const _selectedAddress = addresses.find(
          (a) => a.id === selectedAddressId
        );
        const _addresses = addresses
          .map((a) => {
            if (a.deliveryGroupId && a.deliveryGroupId !== "") return a;
            return merge({}, a, {
              deliveryGroupId: getDeliveryGroupIdByCity(a.city),
            });
          })
          .filter((a) => !!a.deliveryGroupId && a.deliveryGroupId !== "")
          .map((address) =>
            merge({}, address, {
              bellName:
                !!address.bellName && address.bellName !== ""
                  ? address.bellName
                  : address.lastName,
            })
          );
        const _firstName = !!_selectedAddress
          ? _selectedAddress.firstName
          : null;
        const _lastName = !!_selectedAddress ? _selectedAddress.lastName : null;
        const _phoneNumber =
          !!phoneNumber && PhoneRegex.test(phoneNumber) ? phoneNumber : null;
        const _email = !!email && EmailRegex.test(email) ? email : null;

        //Update Storage
        setAddresses(_addresses);
        if (_addresses.findIndex((a) => a.id === selectedAddressId) === -1)
          setSelectedAddressId(null);
        if (!!_firstName && !firstName) setFirstName(_firstName);
        if (!!_lastName && !lastName) setLastName(_lastName);
        // If Phone and Email didnt pass regex, set to null
        if (!_phoneNumber) setPhonenNumber(null);
        if (!_email) setEmail(null);
        setSubscribeNewsletter(!vetoNewsletter);

        setMigratedToV2(true);
      }
      setInitialized();
    }
  }, [isReady]);
};

export default useMigration;
