import { useCallback, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import { register } from "../../api/Account";
import * as GenericAPI from "../../api/GenericAccount";
import { resetPassword } from "../../api/GenericAccount";
import { whoAmI } from "../../api/GenericAccount/genericAccount.api";
import { GenericLoginRequest } from "../../api/GenericAccount/genericAccount.interfaces";
import { TokenState } from "../../state/Auth/Auth.state";
import {
  B2BAuthTokenStorageAdapter,
  UserAuthTokenStorageAdapter,
} from "../../util/storage/auth.storage";
import { setMouseFlowVariable } from "../../util/tracking.util";
import useB2B from "./hooks/useB2B";
import useB2C from "./hooks/useB2C";
import { IUseAccount, IUseAccountBase } from "./useAccount.interfaces";
import AddressBookEntryInfo = Definitions.AddressBookEntryInfo;
import B2CCreateAccountRequest = Definitions.B2CCreateAccountRequest;

const useAccount = (): IUseAccount => {
  const genericToken = useRecoilValue(TokenState);
  const b2b = useB2B();
  const b2c = useB2C();

  const [isLoggedIn, setIsLoggedIn] = useState(
    b2b.isLoggedIn || b2c.isLoggedIn
  );
  const [isB2B, setIsB2B] = useState(b2b.isLoggedIn);
  const [isB2C, setIsB2C] = useState(b2c.isLoggedIn);
  const login = useCallback(
    async (props: GenericLoginRequest) => {
      try {
        const loginResponse = await GenericAPI.login(props);
        if (loginResponse.scope === "B2B") {
          UserAuthTokenStorageAdapter.delete();
          b2b.setToken(loginResponse);
        } else {
          B2BAuthTokenStorageAdapter.delete();
          b2c.setToken(loginResponse);
        }
        return loginResponse;
      } catch (err) {
        console.error("login error", err);
        return null;
      }
    },
    [b2b, b2c]
  );

  const logout = useCallback(() => {
    b2b.setToken(null);
    b2c.setToken(null);
  }, [b2b?.setToken, b2c?.setToken]);

  const getAccountHook = useCallback(() => {
    let user: IUseAccountBase;
    if (b2b.isLoggedIn) {
      user = b2b;
    } else if (b2c.isLoggedIn) {
      user = b2c;
    }
    return user;
  }, [b2b, b2c]);
  const getAddressBook = useCallback(async () => {
    try {
      const account = getAccountHook();
      if (!!account) {
        return await account.getAddressBook();
      }
    } catch (err) {}
  }, [getAccountHook, b2b, b2c]);

  const saveAddress = useCallback(
    async (address: Omit<AddressBookEntryInfo, "id">) => {
      try {
        const account = getAccountHook();
        if (!!account) {
          return await account.saveAddress(address);
        }
      } catch (err) {}
    },
    [getAccountHook, b2b, b2c]
  );
  const updateAddress = useCallback(
    async (address: AddressBookEntryInfo) => {
      try {
        const account = getAccountHook();
        if (!!account && address?.id) {
          return await account.updateAddress(address);
        }
      } catch (err) {}
    },
    [getAccountHook, b2b, b2c]
  );
  const setStandardDeliveryAddress = useCallback(
    async (addressId: string) => {
      try {
        const account = getAccountHook();
        if (!!account) {
          return await account.setStandardDeliveryAddress(addressId);
        }
      } catch (err) {}
    },
    [getAccountHook, b2b, b2c]
  );
  const setStandardInvoiceAddress = useCallback(
    async (addressId: string) => {
      try {
        const account = getAccountHook();
        if (!!account) {
          return await account.setStandardInvoiceAddress(addressId);
        }
      } catch (err) {}
    },
    [getAccountHook, b2b, b2c]
  );
  const deleteAddress = useCallback(
    async (addressId: string) => {
      try {
        const account = getAccountHook();
        if (!!account) {
          return await account.deleteAddress(addressId);
        }
      } catch (err) {}
    },
    [getAccountHook, b2b, b2c]
  );

  const _resetPassword = useCallback(
    async (email: string) => {
      try {
        await resetPassword(email);
      } catch (err) {}
    },
    [getAccountHook]
  );

  const registerAccount = useCallback(async (data: B2CCreateAccountRequest) => {
    try {
      return await register(data);
    } catch (err) {
      console.error("register error", err);
    }
  }, []);

  useEffect(() => {
    setIsLoggedIn(b2b.isLoggedIn || b2c.isLoggedIn);
    setIsB2B(b2b.isLoggedIn);
    setIsB2C(b2c.isLoggedIn);
  }, [b2b?.isLoggedIn, b2c?.isLoggedIn]);

  useEffect(() => {
    setMouseFlowVariable("isB2BUser", `${isB2B}`);
  }, [isB2B]);
  useEffect(() => {
    setMouseFlowVariable("isAccountUser", `${isB2C}`);
  }, [isB2C]);

  const userAccount = whoAmI(genericToken?.id);

  return {
    isLoggedIn: b2b.isLoggedIn || b2c.isLoggedIn,
    isB2B,
    isB2C,
    getAddressBook,
    saveAddress,
    updateAddress,
    setStandardDeliveryAddress,
    setStandardInvoiceAddress,
    deleteAddress,
    userAccount,
    companyDetails: b2b?.companyDetails,
    login,
    logout,
    resetPassword: _resetPassword,
    registerAccount,
  };
};

export default useAccount;
