import useSWR, { SWRConfiguration } from "swr";

import { APIResponse, FetcherFunctionResponse } from "../fetcher";
import { B2BAPIFetcher } from "../fetcher/b2bAPI";
import AddressBookInfo = Definitions.AddressBookInfo;
import AddressBookEntryInfo = Definitions.AddressBookEntryInfo;
import BasicAccountInfo = Definitions.BasicAccountInfo;
import B2BCompanyInfo = Definitions.B2BCompanyInfo;

export const getAddressBook = async () => {
  return B2BAPIFetcher<AddressBookInfo>("/b2b/company/addressbook");
};

export const saveAddress = async (
  address: Omit<AddressBookEntryInfo, "id">
) => {
  return B2BAPIFetcher<AddressBookEntryInfo>("/b2b/company/addressbook", {
    method: "POST",
    data: address,
  });
};

export const updateAddress = async (address: AddressBookEntryInfo) => {
  return B2BAPIFetcher<AddressBookInfo>(
    `/b2b/company/addressbook/${address.id}`,
    {
      method: "PUT",
      data: address,
    }
  );
};

export const setStandardDeliveryAddress = async (id: string) => {
  return B2BAPIFetcher<any>(
    `/b2b/company/addressbook/standarddeliveryaddress/${id}`,
    {
      method: "PUT",
    }
  );
};

export const setStandardInvoiceAddress = async (id: string) => {
  return B2BAPIFetcher<any>(
    `/b2b/company/addressbook/standardinvoiceaddress/${id}`,
    {
      method: "PUT",
    }
  );
};

export const deleteAddress = async (id: string) => {
  return B2BAPIFetcher<any>(`/b2b/company/addressbook/${id}`, {
    method: "DELETE",
  });
};
export const getCompany = (
  _userToken: string,
  swrConfig?: SWRConfiguration
): FetcherFunctionResponse<B2BCompanyInfo | undefined> => {
  const { error, data, mutate } = useSWR<B2BCompanyInfo, string>(
    () => (!!_userToken ? `company-details#${_userToken}` : null),
    async () => {
      const response = await B2BAPIFetcher<APIResponse<B2BCompanyInfo>>(
        "/b2b/company",
        {
          token: _userToken,
        }
      );
      return response.payload as B2BCompanyInfo; // Add a type assertion to ensure the correct return type
    },
    swrConfig
  );
  return {
    error,
    data,
    mutate,
  };
};
export const whoAmI = (
  _userToken: string,
  swrConfig?: SWRConfiguration
): FetcherFunctionResponse<BasicAccountInfo | undefined> => {
  const { error, data, mutate } = useSWR<BasicAccountInfo, string>(
    () => (!!_userToken ? `whoami#${_userToken}` : null),
    () =>
      B2BAPIFetcher<BasicAccountInfo>("/b2c/account/whoami", {
        token: _userToken,
      }),
    swrConfig
  );
  return {
    error,
    data,
    mutate,
  };
};
