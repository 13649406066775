import { ErrorType } from "src/hooks/useErrorHandler/useErrorHandler.interfaces";
import CartItem = Definitions.CartItem;
import HydratedDietaryInfo = Definitions.HydratedDietaryInfo;
import HydratedProduct = Definitions.HydratedProduct;

export interface useConfiguratorProps {}

export interface ICategory {
  name: string;
  id: string;
  maxSelection?: number;
  products: any[];
  header: string;
}

export interface IDisplayedCategory extends Omit<ICategory, "products"> {
  productSegments: HydratedProduct[][];
}

export enum ProductCategories {
  BOWLS = "BOWL",
  DESSERTS = "DESSERT",
  EXTRAS = "EXTRA",
  DRINKS = "DRINK",
  STANDARDS = "STANDARD",
  PREMIUMS = "PREMIUM",
  TOPPINGS = "TOP",
  DRESSINGS = "DRESSING",
  BASES = "BASE",
  SIDES = "SIDE",
  ADDONS = "ADDON",
  TOP_SELLER = "TOP_SELLER",
  SEASONALS = "SEASONALS",
  SEASONALS_SPRING = "SEASONALS_SPRING",
  SEASONALS_SUMMER = "SEASONALS_SUMMER",
  SEASONALS_AUTUMN = "SEASONALS_AUTUMN",
  SEASONALS_WINTER = "SEASONALS_WINTER",
  MEAT = "MEAT",
  NOMEAT = "NOMEAT",
  CUSTOMS = "CUSTOMS",
}

interface OrderProductCount {
  [index: string]: number;
}

export type FlattenedSelectedProductMap = { [index: string]: number };

export type ShadowOrderCategories = {
  [key in ProductCategories]?: FlattenedSelectedProductMap;
};

export interface SelectedProductsByCategory extends ShadowOrderCategories {
  product: OrderProductCount;
}

export interface ConfiguratorProduct {
  nutrients: HydratedDietaryInfo;
  bowlPrice: number;
  totalPrice: number;
  count: number;
  popularUpsellProducts: string[];
}

export interface IUseConfigurator {
  onClose: () => void;
  addBowlToCart: () => void;
  categories: ICategory[];
  activeCategory: string;
  toggleBowlIngredient: (ingredientName: string) => void;
  configuratorShadowCart: CartItem[];
  configuratorProduct: ConfiguratorProduct;
  onClickNextStep: () => void;
  onClickPriorStep: () => void;
  onClickNavbarItem: (category: IDisplayedCategory) => void;
  isContineuButtonDisabled: boolean;
  showBaseIngredientError: boolean;
  image: string;
  configuratorProductErrors: ErrorType[];
  removeUnavailableProducts: () => Promise<void>;
  setUpsellCount: (addonId: string, type: string, count: number) => void;
  onClickForceAddToCart: () => void;
}
