import axios from "axios";

import { B2BAuthTokenStorageAdapter } from "../../../util/storage/auth.storage";
import {
  APIFetcher,
  APIFetcherWithAxiosResponse,
  GenerateAPIFetcher,
} from "../fetcher.interfaces";

const B2BAxios = axios.create();
B2BAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      B2BAuthTokenStorageAdapter.delete();
    }
    return Promise.reject(error);
  }
);
const B2BAPIFetcherGenerator: GenerateAPIFetcher =
  (axiosWrapper) =>
  async <T>(url, options) => {
    const token = !!options?.token
      ? options.token
      : B2BAuthTokenStorageAdapter.get();
    if (!token) return null;
    const _options = {
      ...options,
      headers: {
        ...(options?.headers
          ? { ...options.headers }
          : {
              Authorization: `Bearer ${token}`,
            }),
      },
    };
    const response = await B2BAxios<T>(
      `${process.env.NEXT_PUBLIC_API_BASE}${url}`,
      _options
    );
    return axiosWrapper ? response : response.data;

    /*
     * Improve Error Handling with Custom Errors ?
     * */
  };

export const B2BAPIFetcher = B2BAPIFetcherGenerator(false) as APIFetcher;
export const B2BAPIFetcherWithAxiosWrapper = B2BAPIFetcherGenerator(
  true
) as APIFetcherWithAxiosResponse;

export const isB2BUser = (): boolean => {
  const token = B2BAuthTokenStorageAdapter.get();
  if (!!token && token !== "null") return true;
  return false;
};
