import { CheckoutError } from "../../views/CheckoutPageView/CheckoutPage/CheckoutPage.interfaces";
import { DropInPaymentType } from "../useAdyen/useAdyen.interfaces";

export enum InputError {
  FIELD_MISSING = "fieldMissing",
  FIELD_INVALID = "fieldInvalid",
  NAME_MISSING = "nameMissing",
  ADDRESS_MISSING = "addressMissing",
  EMAIL_ADDRESS_MISSING = "emailAddressMissing",
  PHONE_NUMBER_MISSING = "phoneNumberMissing",
  ADDRESS_INVALID = "addressInvalid",
}

export type InvalidOrderTimeInputCause = "IN_PAST" | "NOT_WITHIN_OPENING_HOURS";

export const InputErrors: InputError[] = [
  InputError.FIELD_MISSING,
  InputError.FIELD_INVALID,
  InputError.NAME_MISSING,
  InputError.ADDRESS_MISSING,
  InputError.EMAIL_ADDRESS_MISSING,
  InputError.PHONE_NUMBER_MISSING,
  InputError.ADDRESS_INVALID,
];

export enum ProductError {
  PRODUCT_NOT_FOUND = "productNotFound",
  PRODUCT_UNAVAILABLE = "productUnavailable",
}

export const ProductErrors: ProductError[] = [
  ProductError.PRODUCT_NOT_FOUND,
  ProductError.PRODUCT_UNAVAILABLE,
];

export enum OrderError {
  ORDER_IS_NULL = "orderIsNull",
  STORE_NOT_FOUND = "storeNotFound",
  REQUESTED_ORDER_TIME_INVALID = "requestedOrderTimeInvalid",
  DELIVERY_TYPE_NOT_SUPPORTED = "deliveryTypeNotSupported",
  CHANGE_REQUEST_INVALID = "changeRequestInvalid",
}

export const OrderErrors: OrderError[] = [
  OrderError.ORDER_IS_NULL,
  OrderError.STORE_NOT_FOUND,
  OrderError.REQUESTED_ORDER_TIME_INVALID,
  OrderError.DELIVERY_TYPE_NOT_SUPPORTED,
  OrderError.CHANGE_REQUEST_INVALID,
];

export enum VoucherError {
  VOUCHER_INVALID = "voucherInvalid",
  VOUCHER_EXPIRED = "voucherExpired",
  VOUCHER_ALLOCATION_FAILED = "voucherAllocationFailed",
  VOUCHERMANAGER_COMMUNICATION_FAILED = "vouchermanagerCommunicationFailed",
  BELOW_VOUCHER_THRESHOLD = "belowVoucherThreshold",
  VOUCHER_ONLY_FOR_NEW_CUSTOMER = "voucherOnlyForNewCustomer",
  VOUCHER_CREATED_FOR_STREET_MISMATCH = "voucherCreatedForStreetMismatch",
  VOUCHER_RESTRICTED_TO_DIFFERENT_DELIVERY_TIME = "voucherRestrictedToDifferentDeliveryTime",
  VOUCHER_RESTRICTED_TO_DIFFERENT_EMAIL_DOMAIN = "voucherRestrictedToDifferentEmailDomain",
  VOUCHER_ALREADY_USED_BY_CUSTOMER = "voucherAlreadyUsedByCustomer",
  VOUCHER_ALREADY_USED_BY_CUSTOMER_ON_SAME_DAY = "voucherAlreadyUsedByCustomerOnSameDay",
  VOUCHER_RESTRICTED_TO_DIFFERENT_STORE = "voucherRestrictedToDifferentStore",
  VOUCHER_RESTRICTED_TO_DIFFERENT_DELIVERY_GROUP = "voucherRestrictedToDifferentDeliveryGroup",
  VOUCHER_REQUIRES_MORE_DATA = "voucherRequiresMoreData",
  VOUCHER_NO_CREDIT = "voucherHasNoCredit",
}

export const VoucherErrors: VoucherError[] = [
  VoucherError.VOUCHER_INVALID,
  VoucherError.VOUCHER_EXPIRED,
  VoucherError.VOUCHER_ALLOCATION_FAILED,
  VoucherError.VOUCHERMANAGER_COMMUNICATION_FAILED,
  VoucherError.BELOW_VOUCHER_THRESHOLD,
  VoucherError.VOUCHER_ONLY_FOR_NEW_CUSTOMER,
  VoucherError.VOUCHER_CREATED_FOR_STREET_MISMATCH,
  VoucherError.VOUCHER_RESTRICTED_TO_DIFFERENT_DELIVERY_TIME,
  VoucherError.VOUCHER_RESTRICTED_TO_DIFFERENT_EMAIL_DOMAIN,
  VoucherError.VOUCHER_ALREADY_USED_BY_CUSTOMER,
  VoucherError.VOUCHER_ALREADY_USED_BY_CUSTOMER_ON_SAME_DAY,
  VoucherError.VOUCHER_RESTRICTED_TO_DIFFERENT_STORE,
  VoucherError.VOUCHER_RESTRICTED_TO_DIFFERENT_DELIVERY_GROUP,
  VoucherError.VOUCHER_REQUIRES_MORE_DATA,
  VoucherError.VOUCHER_NO_CREDIT,
];

export enum PaymentError {
  PAYMENT_FAILED = "paymentFailed",
  UNHANDLED_PAYMENT = "unhandledPayment",
  STORED_CREDITCARD_INVALID = "storedPaymentTokenNotValid",
}

export const PaymentErrors = [
  PaymentError.PAYMENT_FAILED,
  PaymentError.UNHANDLED_PAYMENT,
  PaymentError.STORED_CREDITCARD_INVALID,
];

export enum GenericError {
  GENERIC_ERROR = "genericError",
}

export enum UserError {
  PASSWORD_TOO_SHORT = "passwordTooShort",
  PIN_INVALID = "pinInvalid",
  USER_EXISTS = "userExists",
  USER_UNKNOWN = "userUnknown",
  INVALID_USER_TOKEN = "invalidUserToken",
}

export const UserErrors: UserError[] = [
  UserError.PASSWORD_TOO_SHORT,
  UserError.PIN_INVALID,
  UserError.USER_EXISTS,
  UserError.USER_UNKNOWN,
  UserError.INVALID_USER_TOKEN,
];

export enum VytalError {
  INVALID_VYTAL_CODE = "invalidVytalCode",
}

export enum PlaceOrderError {
  FAILED = "placeOrderFailed",
}

export interface ErrorType {
  type:
    | InputError
    | ProductError
    | OrderError
    | VoucherError
    | PaymentError
    | GenericError
    | UserError
    | VytalError
    | PlaceOrderError
    | CheckoutError;
  cause?: string;
  id?: string;
}

export interface IUseErrorHandler {
  errors: ErrorType[];
  setErrors: (errors: ErrorType[]) => void;
  addErrors: (errors: ErrorType[]) => void;
  resetErrors: (errorType?: ErrorType["type"] | ErrorType["type"][]) => void;
  getErrorMessageByType: (
    type: ErrorType["type"] | CheckoutError,
    cause?: string
  ) => string;
  inputError: ErrorType;
  productErrors: ErrorType[];
  orderError: ErrorType;
  voucherError: ErrorType;
  paymentError: ErrorType;
  genericError: ErrorType;
  userError: ErrorType;
  vytalError: ErrorType;
  placeOrderError: ErrorType;
}

export type CMSErrorMap = {
  [key in ErrorType["type"] | CheckoutError]: string;
};

export interface UseErrorHandlerProps {
  cmsErrorMap?: CMSErrorMap;
}

interface AdyenCreditCardPaymentMethodData {
  type: DropInPaymentType.CREDIT_CARD;
  brand: string;
  checkoutAttemptId: string;
  encryptedCardNumber: string;
  encryptedExpiryMonth: string;
  encryptedExpiryYear: string;
  encryptedSecurityCode: string;
  holderName: string;
}

interface AdyenStoredPaymentMethodData {
  type: DropInPaymentType.CREDIT_CARDV2;
  encryptedSecurityCode: string;
  storedPaymentMethodId: string;
}

interface AdyenGooglePaymentMethodData {
  type: DropInPaymentType.GOOGLE_PAY;
  googlePayToken: string;
  googlePayCardNetwork: string;
}

interface AdyenApplePaymentMethodData {
  type: DropInPaymentType.APPLE_PAY;
  applePayToken: string;
}

interface AdyenDirectPayPaymentMethodData {
  type: DropInPaymentType.DIRECT_PAY;
}

export interface AdyenOnSubmitData {
  data: {
    paymentMethod:
      | AdyenCreditCardPaymentMethodData
      | AdyenApplePaymentMethodData
      | AdyenGooglePaymentMethodData
      | AdyenDirectPayPaymentMethodData
      | AdyenStoredPaymentMethodData;
    storePaymentMethod: boolean;
  };
  valid: {
    billingAddress: boolean;
    encryptedSecurityCode: boolean;
    holderName: boolean;
    socialSecurityNumber: boolean;
    taxNumber: boolean;
  };
  isValid: boolean;
}
