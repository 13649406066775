import { Dispatch, MutableRefObject, SetStateAction } from "react";
import { InvoiceAddress } from "src/hooks/useInvoiceAddress/useInvoiceAddress.interfaces";
import { UseTipProps } from "src/hooks/useTip/useTip.interfaces";

import { IUseAddress } from "../../../hooks/useAddress";
import {
  AddToCartMethod,
  RemoveFromCartMethod,
} from "../../../hooks/useCart/useCart.interfaces";
import { IUseErrorHandler } from "../../../hooks/useErrorHandler/useErrorHandler.interfaces";
import { IUseUserInfos } from "../../../hooks/useUserInfos/useUserInfos.interfaces";
import { IUseVoucher } from "../../../hooks/useVoucher/useVoucher.interfaces";
import { InternalCartItem } from "../../../util/order";
import { OnlineDeliveryType } from "../../../util/storage/order.storage";
import {
  CheckoutPageCMSData,
  CheckoutPaths,
} from "../CheckoutPageView.interfaces";
import { IUseCheckoutSteps } from "../hooks/useCheckoutSteps";
import { IUseModal } from "../hooks/useModal/useModal.interfaces";
import { IUseVytal } from "../hooks/useVytal";
import DeliveryGroup = Definitions.DeliveryGroup;
import StoreInfo = Definitions.StoreInfo;
import DeliveryGroupInfo = Definitions.DeliveryGroupInfo;

export interface CheckoutPageProps {
  modal: IUseModal;
  voucher: IUseVoucher;
  checkoutSteps: IUseCheckoutSteps;
  activeDeliveryGroup: DeliveryGroupInfo;
  activePath?: CheckoutPaths;
  activeStore: StoreInfo;
  cartItems: InternalCartItem[];
  cmsData: CheckoutPageCMSData;
  cutleryQuantity: number;
  deliveryPriceWithVat: string;
  transactionFee: string;
  serviceFee: string;
  deliveryType: OnlineDeliveryType;
  endPriceWithVat: number;
  isCartExpanded: boolean;
  isStorePickerOpen: boolean;
  errorHandler: IUseErrorHandler;
  myData: {
    userInfos: IUseUserInfos;
    address: IUseAddress;
    selectedAddressId: string;
    activeDeliveryGroupId: string;
    isInvoiceAddressDifferent: boolean;
    setIsInvoiceAddressDifferent: (value: boolean) => void;
    invoiceAddress: InvoiceAddress;
    updateInvoiceAddress: (address: Partial<InvoiceAddress>) => void;
    setInvoiceAddressEnabled: (enabled: boolean) => void;
    invoiceAddressEnabled: boolean;
    projectCode: string;
    setProjectCode: (projectCode: string) => void;
    myDataAddressErrorRef: MutableRefObject<HTMLDivElement>;
    pickUpInputGroupRef: MutableRefObject<HTMLDivElement>;
    streetInputRef: MutableRefObject<HTMLInputElement>;
    accountEmailAddress: string;
  };
  napkinQuantity: number;
  onClickDecreaseCutleryQuantity: () => void;
  onClickDecreaseNapkinQuantity: () => void;
  onClickExpandCart: () => void;
  onClickIncreaseCutleryQuantity: () => void;
  onClickIncreaseNapkinQuantity: () => void;
  openCartPage: () => void;
  pageIsLoading: boolean;
  preFetchedDeliveryGroups: DeliveryGroup[];
  removeFromCart: RemoveFromCartMethod;
  addToCart: AddToCartMethod;
  setActiveDeliveryGroupById: (id: string) => void;
  setDeliveryType: (deliveryType: OnlineDeliveryType) => void;
  setIsStorePickerOpen: Dispatch<SetStateAction<boolean>>;
  submitOrder: () => Promise<void>;
  goToStorePicker: () => void;
  cartInvalidBannerRef: MutableRefObject<HTMLDivElement>;
  tip: UseTipProps;
  vytal: IUseVytal;
  isCartValid: boolean;
  agbLabelRef: MutableRefObject<HTMLDivElement>;
  agbIsInView: boolean;
  scrollToAGB: () => void;
}

export enum CheckoutError {
  INPUT_INVALID = "INPUT_INVALID",
  EMAIL_MISSING = "EMAIL_MISSING",
  PHONE_NUMBER_MISSING = "PHONE_NUMBER_MISSING",
  ADDRESS_MISSING = "ADDRESS_MISSING",
  ADDRESS_NOT_IN_DELIVERY_AREA = "ADDRESS_NOT_IN_DELIVERY_AREA",
  ADDRESS_INVALID = "ADDRESS_INVALID",
  DELIVERY_TIMESLOT_MISSING = "DELIVERY_TIMESLOT_MISSING",
  DELIVERY_TIMESLOT_IN_PAST = "DELIVERY_TIMESLOT_IN_PAST",
  DELIVERY_TIMESLOT_NOT_WITHIN_OPENING_HOURS = "DELIVERY_TIMESLOT_NOT_WITHIN_OPENING_HOURS",
  DELIVERY_TIMESLOT_UNAVAILABLE = "DELIVERY_TIMESLOT_UNAVAILABLE",
  PICKUP_TIMESLOT_MISSING = "PICKUP_TIMESLOT_MISSING",
  PICKUP_TIMESLOT_UNAVAILABLE = "PICKUP_TIMESLOT_UNAVAILABLE",
  PICKUP_TIMESLOT_IN_PAST = "PICKUP_TIMESLOT_IN_PAST",
  PICKUP_TIMESLOT_NOT_WITHIN_OPENING_HOURS = "PICKUP_TIMESLOT_NOT_WITHIN_OPENING_HOURS",
  PAYMENT_METHOD_MISSING = "PAYMENT_METHOD_MISSING",
  VOUCHER_INVALID = "VOUCHER_INVALID",
  STORED_CREDIT_CARD_INVALID = "STORED_CREDIT_CARD_INVALID",
  CREDIT_CARD_INVALID = "CREDIT_CARD_INVALID",
  CART_INVALID = "CART_INVALID",
}
