import styled from "@emotion/styled";
import { LibTheme } from "@stadtsalat/component-lib";

export const _PickUpInputsWrapper = styled.form`
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1rem;
  margin-bottom: 2rem;
  @media (min-width: ${LibTheme.mediaBreakpoints.tablet}) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
    margin-bottom: 1.5rem;
  }
`;
