import { useRecoilState } from "recoil";

import {
  deleteAddress,
  getAddressBook,
  saveAddress,
  setStandardDeliveryAddress,
  setStandardInvoiceAddress,
  updateAddress,
  whoAmI,
} from "../../../../api/Account/account.api";
import { GenericLoginResponse } from "../../../../api/GenericAccount/genericAccount.interfaces";
import { TokenState, UserAuthState } from "../../../../state/Auth/Auth.state";
import { IUseAccountBase, TGenericToken } from "../../useAccount.interfaces";
import AddressBookEntryInfo = Definitions.AddressBookEntryInfo;

export const isTokenValid = (token: string | null): boolean =>
  !!token && token !== "null";

const getUserToken = (
  legacyUserToken: string | null,
  genericToken: TGenericToken | null
) => {
  if (!!genericToken) {
    if (genericToken.scope === "B2C") {
      return genericToken.id;
    } else {
      return null;
    }
  }
  return legacyUserToken;
};

const useB2C = (): IUseAccountBase => {
  const [legacyUserToken, setLegacyUserToken] = useRecoilState(UserAuthState);
  const [genericToken, setGenericToken] = useRecoilState(TokenState);
  const isLoggedIn = !!getUserToken(legacyUserToken, genericToken);
  const userAccount = whoAmI(getUserToken(legacyUserToken, genericToken));

  const _getAddressBook = async () => {
    try {
      const addressBook = await getAddressBook();
      return addressBook;
    } catch (err) {}
  };

  const _saveAddress = async (address: Omit<AddressBookEntryInfo, "id">) => {
    try {
      return await saveAddress(address);
    } catch (err) {}
  };

  const _updateAddress = async (updatedAddress: AddressBookEntryInfo) => {
    try {
      return await updateAddress(updatedAddress);
    } catch (err) {}
  };

  const _deleteAddress = async (id: string) => {
    try {
      await deleteAddress(id);
    } catch (err) {}
  };

  const _setStandardDeliveryAddress = async (id: string) => {
    try {
      return await setStandardDeliveryAddress(id);
    } catch (err) {}
  };

  const _setStandardInvoiceAddress = async (id: string) => {
    try {
      return await setStandardInvoiceAddress(id);
    } catch (err) {}
  };
  return {
    isLoggedIn,
    getAddressBook: _getAddressBook,
    saveAddress: _saveAddress,
    updateAddress: _updateAddress,
    deleteAddress: _deleteAddress,
    setStandardDeliveryAddress: _setStandardDeliveryAddress,
    setStandardInvoiceAddress: _setStandardInvoiceAddress,
    userAccount,
    setToken: (loginResponse?: GenericLoginResponse) => {
      if (loginResponse) {
        const { id, scope, userId, ttl, timeout } = loginResponse;
        setGenericToken({ id, scope, userId, ttl, timeout });
        setLegacyUserToken(id);
      } else {
        setGenericToken(null);
        setLegacyUserToken(null);
      }
    },
  };
};

export default useB2C;
