import { useCallback } from "react";
import { useRecoilState } from "recoil";

import {
  EmailState,
  InputGroupValidState,
  PhoneNumberState,
} from "../../state/User/User.state";
import useErrorHandler from "../useErrorHandler";
import { InputError } from "../useErrorHandler/useErrorHandler.interfaces";
import { IUseUserInfos } from "./useUserInfos.interfaces";

const useUserInfos = (): IUseUserInfos => {
  const { inputError, resetErrors } = useErrorHandler();
  const [phoneNumber, setPhoneNumber] = useRecoilState(PhoneNumberState);
  const [inputGroupValid, setInputGroupValid] =
    useRecoilState(InputGroupValidState);
  const [email, setEmail] = useRecoilState(EmailState);

  const _setPhoneNumber = useCallback(
    (phoneNumber: string) => {
      setPhoneNumber(phoneNumber);
      if (inputError && inputError?.type === InputError.PHONE_NUMBER_MISSING) {
        resetErrors(InputError.PHONE_NUMBER_MISSING);
      }
    },
    [setPhoneNumber, inputError, resetErrors]
  );
  const _setEmail = useCallback(
    (email: string) => {
      setEmail(email);
      if (inputError && inputError?.type === InputError.EMAIL_ADDRESS_MISSING) {
        resetErrors(InputError.EMAIL_ADDRESS_MISSING);
      }
    },
    [setEmail, inputError, resetErrors]
  );
  return {
    email,
    setEmail: _setEmail,
    phoneNumber,
    setPhoneNumber: _setPhoneNumber,
    inputGroupValid,
    setInputGroupValid,
  };
};

export default useUserInfos;
