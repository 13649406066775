import { atom } from "recoil";

import { Address, NewAddress } from "../../hooks/useAddress";

export const FilteredAddressState = atom<Address[]>({
  key: "_FilteredAddressState",
  default: [],
});

export const EditingAddressState = atom<Address | null>({
  key: "_EditingAddressState",
  default: null,
});

export const NewAddressState = atom<NewAddress | null>({
  key: "_NewAddressState",
  default: null,
});

export const NewAddressValidatedState = atom<boolean>({
  key: "_NewAddressValidatedState",
  default: false,
});

export const NewAddressInvalidState = atom<boolean>({
  key: "_NewAddressInvalidState",
  default: false,
});

export const StoreAddressState = atom<boolean>({
  key: "_StoreAddressState",
  default: true,
});

export const IsAddressInDeliveryAreaState = atom<boolean>({
  key: "_IsAddressInDeliveryAreaState",
  default: false,
});

export const ShowAddressDropDownState = atom<boolean>({
  key: "_ShowAddressDropDownState",
  default: false,
});
export const NewAddressStreetState = atom<string>({
  key: "_NewAddressStreetState",
  default: "",
});
export const NewAddressHouseNoState = atom<string>({
  key: "_NewAddressHouseNoState",
  default: "",
});

export const NewAddressZipCodeState = atom<string>({
  key: "_NewAddressZipCodeState",
  default: "",
});
export const NewAddressCityState = atom<string>({
  key: "_NewAddressCityState",
  default: "",
});
