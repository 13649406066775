import { useCallback, useEffect } from "react";

import { IUseClickOutsideProps } from "./useClickOutside.interfaces";

const useClickOutside = (props: IUseClickOutsideProps): void => {
  const { onClick, ref, addEventListener = true } = props;

  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
        onClick();
      }
    },
    [onClick, ref]
  );
  useEffect(() => {
    if (addEventListener) {
      document.addEventListener("mousedown", handleClick);
      return () => {
        document.removeEventListener("mousedown", handleClick);
      };
    }
  }, [handleClick, addEventListener]);
};

export default useClickOutside;
