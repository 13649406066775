import { BaseInput } from "@stadtsalat/component-lib";
import { useRouter } from "next/router";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Controller } from "react-hook-form";

import { CheckoutError } from "../../../../CheckoutPage.interfaces";
import { PickUpInputsProps } from "./PickUpInputs.interfaces";
import { _PickUpInputsWrapper } from "./PickUpInputs.styled";

enum FieldNames {
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  PHONE = "phone",
  EMAIL = "email",
}

export const EmailRegex = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
export const PhoneRegex = new RegExp(/^[0-9 +()-]+$/);

const PickUpInputs = React.forwardRef<
  HTMLDivElement,
  Omit<PickUpInputsProps, "ref">
>((props, ref) => {
  const {
    active,
    firstName = "",
    setFirstName,
    lastName = "",
    setLastName,
    phone = "",
    email = "",
    setPhone,
    setEmail,
    setInputGroupValid,
    setComponentInitialized,
    form,
    cmsData,
    isEmailDisabled,
    validationErrors,
  } = props;

  const { isReady } = useRouter();
  const { control, formState, getValues, trigger, clearErrors } = form;
  const { errors, isValid } = formState;
  const [_firstName, _setFirstName] = useState(firstName);
  const [_lastName, _setLastName] = useState(lastName);
  const [_phone, _setPhone] = useState(phone);
  const [_email, _setEmail] = useState(email);
  const firstNameRef = React.useRef<HTMLInputElement>(null);
  const lastNameRef = React.useRef<HTMLInputElement>(null);
  const emailRef = React.useRef<HTMLInputElement>(null);
  const phoneRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isReady) {
      trigger().then((valid) => {
        if (valid) {
          setInputGroupValid(true);
        }
        clearErrors();
        setComponentInitialized();
      });
    }
  }, [isReady]);

  useEffect(() => {
    setInputGroupValid(isValid);
    if (isValid) {
      const fieldValues = getValues();
      setFirstName(fieldValues.firstName);
      setLastName(fieldValues.lastName);
      setEmail(fieldValues.email);
      setPhone(fieldValues.phone);
    } else {
      setInputGroupValid(false);
    }
  }, [isValid, _firstName, _lastName, _phone, _email]);

  useEffect(() => {
    if (active) {
      if (!_firstName) {
        firstNameRef.current?.focus();
      } else if (!_lastName) {
        lastNameRef.current?.focus();
      } else if (!_email) {
        emailRef.current?.focus();
      } else if (!_phone) {
        phoneRef.current?.focus();
      }
    }
  }, [active]);

  useEffect(() => {
    if (
      validationErrors.some((err) =>
        [CheckoutError.EMAIL_MISSING, CheckoutError.INPUT_INVALID].includes(err)
      )
    ) {
      form.trigger();
    }
  }, [validationErrors]);

  const allowedCharsRegex = /^[+()0-9\s-]*#$/;

  const handleInputChange = (value) => {
    // Remove all non-digit characters to count the digits
    const digitsOnly = value.replace(/[^0-9]/g, "");

    // If the number of digits exceeds 15, truncate the digits
    if (digitsOnly.length > 15) {
      const truncatedDigits = digitsOnly.slice(0, 15);
      // Reconstruct the value with truncated digits and allowed special characters
      let truncatedValue = "";
      let digitIndex = 0;
      for (let char of value) {
        if (/[0-9]/.test(char)) {
          if (digitIndex < 15) {
            truncatedValue += truncatedDigits[digitIndex++];
          }
        } else if (/[+()# \s-]/.test(char)) {
          truncatedValue += char;
        }
      }
      return truncatedValue;
    }

    // If the value matches the allowed characters regex, return it
    if (allowedCharsRegex.test(value)) {
      return value;
    } else {
      // Otherwise, remove invalid characters
      return value.replace(/[^+()#0-9\s-]/g, "");
    }
  };

  return (
    <div ref={ref}>
      <_PickUpInputsWrapper>
        <Controller
          name={FieldNames.FIRST_NAME}
          control={control}
          defaultValue={firstName}
          rules={{
            required: {
              value: true,
              message: cmsData.errorMessages.firstNameMissing,
            },
          }}
          render={({ field }) => (
            <BaseInput
              autocomplete={"shipping given-name"}
              isInvalid={!!errors?.firstName?.message}
              errorMessage={errors?.firstName?.message}
              id={"input-firstname"}
              value={field.value}
              label={cmsData.firstNameLabel}
              onBlur={() => form.trigger("firstName")}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                field.onChange(event);
                _setFirstName(event.target.value);
              }}
              data-cy={"myData-input-firstname"}
              ref={firstNameRef}
            />
          )}
        />
        <Controller
          name={FieldNames.LAST_NAME}
          control={control}
          defaultValue={lastName}
          rules={{
            required: {
              value: true,
              message: cmsData.errorMessages.lastNameMissing,
            },
          }}
          render={({ field }) => (
            <BaseInput
              autocomplete={"shipping family-name"}
              isInvalid={!!errors?.lastName?.message}
              errorMessage={errors?.lastName?.message}
              id={"input-lastname"}
              value={field.value}
              label={cmsData.lastNameLabel}
              onBlur={() => {
                form.trigger("lastName");
              }}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                field.onChange(event);
                _setLastName(event.target.value);
              }}
              data-cy={"myData-input-lastname"}
              ref={lastNameRef}
            />
          )}
        />
        {!isEmailDisabled ? (
          <Controller
            name={FieldNames.EMAIL}
            control={control}
            defaultValue={email}
            rules={{
              required: {
                value: true,
                message: cmsData.errorMessages.emailMissing,
              },
              validate: {
                maxLength: (v) =>
                  v.length <= 50 || cmsData.errorMessages.emailInvalid,
                matchPattern: (v) =>
                  EmailRegex.test(v) || cmsData.errorMessages.emailInvalid,
              },
            }}
            render={({ field }) => (
              <BaseInput
                isDisabled={isEmailDisabled}
                autocomplete={"shipping email"}
                isInvalid={!!errors?.email?.message}
                errorMessage={errors?.email?.message}
                id={"input-mail"}
                value={field.value}
                label={cmsData.emailLabel}
                onBlur={() => form.trigger("email")}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  field.onChange(event);
                  _setEmail(event.target.value);
                  if (!!errors.email?.message) {
                    form.trigger("email");
                  }
                }}
                data-cy={"myData-input-mail"}
                ref={emailRef}
              />
            )}
          />
        ) : (
          <BaseInput
            isDisabled={isEmailDisabled}
            id={"input-mail-disabled"}
            value={email}
            label={cmsData.emailLabel}
            data-cy={"myData-input-mail-disabled"}
            ref={emailRef}
            onChange={() => {}}
          />
        )}
        <Controller
          name={FieldNames.PHONE}
          control={control}
          defaultValue={phone}
          rules={{
            minLength: {
              value: 3,
              message: cmsData.errorMessages.phoneInvalid,
            },
            validate: (v) => {
              const matches = PhoneRegex.test(v);
              let errorMessage;
              if (!matches) {
                let containsAlphabeticChars = false;
                let containsSpecialChars = false;
                [...v].forEach((char) => {
                  if (char.match(/[a-zA-Z]/)) {
                    containsAlphabeticChars = true;
                  } else if (!char.match(/[-+() 0-9]/)) {
                    containsSpecialChars = true;
                  }
                });
                if (containsAlphabeticChars || containsSpecialChars) {
                  errorMessage = `${
                    containsSpecialChars
                      ? cmsData.errorMessages.phoneContainsSpecialChars
                      : ""
                  }${
                    containsAlphabeticChars && containsSpecialChars ? " " : ""
                  }${
                    containsAlphabeticChars
                      ? cmsData.errorMessages.phoneContainsAlphabeticChars
                      : ""
                  }`;
                } else {
                  errorMessage = cmsData.errorMessages.phoneInvalid;
                }
              }
              return !!errorMessage ? errorMessage : true;
            }, ////new RegExp("^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$")
            required: {
              value: true,
              message: cmsData.errorMessages.phoneMissing,
            },
          }}
          render={({ field }) => (
            <BaseInput
              autocomplete={"shipping tel"}
              isInvalid={!!errors?.phone?.message}
              errorMessage={errors?.phone?.message}
              id={"input-phone"}
              value={field.value}
              label={cmsData.phoneLabel}
              onBlur={() => form.trigger("phone")}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                field.onChange(handleInputChange(event.target.value));
                _setPhone(event.target.value);
                if (!!errors.phone?.message) {
                  form.trigger("phone");
                }
              }}
              data-cy={"myData-input-phone"}
              ref={phoneRef}
            />
          )}
        />
      </_PickUpInputsWrapper>
    </div>
  );
});
export default PickUpInputs;
